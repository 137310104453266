// src/NotFound.js
import React from 'react';

const NotFound = () => {
  // Define inline styles
  const containerStyle = {
    backgroundColor: '#f8f9fa', // Light background color
    minHeight: '100vh', // Full height
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const headingStyle = {
    fontSize: '5rem', // Large heading
    fontWeight: 'bold', // Bold text
    color: 'red', // Danger color
  };

  const subHeadingStyle = {
    fontSize: '2rem', // Medium heading
    marginBottom: '1rem', // Space below the subheading
  };

  const paragraphStyle = {
    fontSize: '1.25rem', // Standard paragraph size
    marginBottom: '1.5rem', // Space below the paragraph
  };

  const buttonStyle = {
    padding: '0.5rem 1.5rem', // Button padding
    fontSize: '1rem', // Button font size
    color: '#fff', // Button text color
    backgroundColor: '#007bff', // Primary color
    border: 'none', // Remove border
    borderRadius: '0.25rem', // Rounded corners
    cursor: 'pointer', // Pointer on hover
  };

  return (
    <div style={containerStyle}>
      <section>
        <div className="text-center">
          {/* <!-- Page Features--> */}
          <h1 style={headingStyle}>404</h1>
          <h2 style={subHeadingStyle}>Page Not Found</h2>
          <p style={paragraphStyle}>The page you're looking for doesn't exist.</p>
          <a href="/" style={buttonStyle}>Go to Home</a>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
