import React, { useState } from "react";
import { Link } from "react-router-dom";
import SEO from "../SEO";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [message, setMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate input
    if (!formData.name || !formData.email || !formData.message) {
      setMessage("Please fill in all required fields.");
    } else {
      // Send email (you may need to use an HTTP request library like axios)
      try {
        const response = await fetch("API_ENDPOINT", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          setMessage("Thank you for contacting us!");
        } else {
          setMessage(
            "Sorry, there was an error sending your message. Please try again later."
          );
        }
      } catch (error) {
        setMessage(
          "Sorry, there was an error sending your message. Please try again later."
        );
      }
    }
  };

  return (
    <>
      <SEO
        title="Online Tools & Generators | Web Design, Development & SEO - OnlineToolsFor.com"
        description="OnlineToolsFor.com provides a comprehensive collection of online tools, CSS generators, text editors, and online converters for web designers, developers, and SEO professionals. Our resources make it easy to create and customize your website, optimize your content for search engines, and analyze your website\'s performance. Improve your online presence today with our powerful and user-friendly tools."
      />

      <header class="pt-5">
        <div class="container px-lg-5">
          <div class="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div class="m-4 m-lg-5">
              <h1 class="display-5 fw-bold">Contact Us</h1>
            </div>
          </div>
        </div>
      </header>
      <div class="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb px-3 py-2">
            <li class="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Contact US
            </li>
          </ol>
        </nav>
      </div>
      {/* <!-- Page Content--> */}
      <section class="pt-4 pb-5">
        <div class="container px-lg-5">
          {/* <!-- Page Features--> */}
          <div class="row gx-lg-5">
            <p>
              Much gratitude to you for setting aside some time to visit our
              site! We're reliably restless to hear from our visitors, whether
              it's to answer a request, give input, or simply make proper
              acquaintance.
            </p>
            <div className="col-md-12">
              {/* Display message */}
              {message && (
                <p
                  style={{
                    fontWeight: "bold",
                    color: message.includes("error") ? "red" : "green",
                  }}
                >
                  {message}
                </p>
              )}

              {/* Contact form */}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="5"
                    onChange={handleChange}
                    required
                  />
                </div>
                <br />
                <button type="submit" className="btn btn-primary float-end">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
