// Summary.js
import React from "react";

const Summary = ({ invoiceData, setInvoiceData }) => {
  const handleChange = (field, value) => {
    setInvoiceData({
      ...invoiceData,
      summary: { ...invoiceData.summary, [field]: parseFloat(value) || 0 },
    });
  };

  return (
    <div className="section form-global">
      <label htmlFor="taxdiscount" class="form-label"><b>Tax & Discount:</b></label>
      <input
        className="form-control"
        id="taxdiscount"
        type="number"
        placeholder="Tax Rate (%)"
        value={invoiceData.summary.taxRate}
        min="0"
        onChange={(e) => handleChange("taxRate", e.target.value)}
      />
      <input
        className="form-control"
        type="number"
        placeholder="Discount"
        value={invoiceData.summary.discount}
        min="0"
        onChange={(e) => handleChange("discount", e.target.value)}
      />
    </div>
  );
};

export default Summary;
