// CustomerInfo.js
import React from "react";

const CustomerInfo = ({ invoiceData, setInvoiceData }) => {
  return (
    <div className="section form-global">
      <label htmlFor="customerinfo" class="form-label"><b>Customer Information:</b></label>
      <input
        className="form-control"
        id="customerinfo"
        type="text"
        placeholder="Customer Name"
        value={invoiceData.customer.name}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            customer: { ...invoiceData.customer, name: e.target.value },
          })
        }
      />
      <input
        className="form-control"
        type="text"
        placeholder="Customer Address"
        value={invoiceData.customer.address}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            customer: { ...invoiceData.customer, address: e.target.value },
          })
        }
      />
      <input
        className="form-control"
        type="text"
        placeholder="Phone"
        value={invoiceData.customer.phone}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            customer: { ...invoiceData.customer, phone: e.target.value },
          })
        }
      />
      <input
        className="form-control"
        type="email"
        placeholder="Email"
        value={invoiceData.customer.email}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            customer: { ...invoiceData.customer, email: e.target.value },
          })
        }
      />
    </div>
  );
};

export default CustomerInfo;
