// src/components/SEO.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const SEO = ({ title, description }) => {
  const location = useLocation();

  // Dynamically set the base URL based on the environment
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000' // Local development URL
      : 'https://onlinetoolsfor.com'; // Production URL

  // Construct the canonical URL dynamically
  const canonicalUrl = `${baseUrl}${location.pathname}`;

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default SEO;
