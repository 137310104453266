import React, { useState } from 'react';
import SEO from "../../../SEO";
import { Link } from 'react-router-dom';

const PayPalCalculator = () => {

  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('INR');
  const [feeRate, setFeeRate] = useState(2.9);
  const [fixedFee, setFixedFee] = useState(0);
  const [payPalFee, setPayPalFee] = useState('-');
  const [totalAmount, setTotalAmount] = useState('-');

  const handleFormSubmit = (event) => {
    event.preventDefault();
  
    // Convert input values to numbers
    const amountValue = parseFloat(amount);
    const feeRateValue = parseFloat(feeRate);
    const fixedFeeValue = parseFloat(fixedFee);
  
    if (isNaN(amountValue) || isNaN(feeRateValue) || isNaN(fixedFeeValue)) {
      // Handle invalid input values
      setPayPalFee('-');
      setTotalAmount('-');
      return;
    }
  
    let currencyRate = 1.0;
    switch (currency) {
      case 'USD':
        currencyRate = 0.014;
        break;
      case 'EUR':
        currencyRate = 0.012;
        break;
      case 'GBP':
        currencyRate = 0.01;
        break;
      case 'JPY':
        currencyRate = 1.53;
        break;
      case 'CAD':
        currencyRate = 0.018;
        break;
      default:
        currencyRate = 1.0;
    }
  
    const calculatedPayPalFee = (amountValue * feeRateValue) / 100 + fixedFeeValue;
    const calculatedTotalAmount = amountValue - calculatedPayPalFee;
    const convertedAmount = calculatedTotalAmount * currencyRate;
  
    setPayPalFee(`₹ ${calculatedPayPalFee.toFixed(2)} (${currency} ${convertedAmount.toFixed(2)})`);
    setTotalAmount(`₹ ${calculatedTotalAmount.toFixed(2)} (${currency} ${convertedAmount.toFixed(2)})`);
  };
  

  return (
    <>
    <SEO
        title="Paypal Fee Calculator - Calculate Paypal Fee Quickly and Easily | onlinetoolsfor.com"
        description="Use our free Paypal Fee Calculator to easily calculate the fees for sending or receiving money through Paypal. Simply enter the amount, select the currency, and choose whether you are sending or receiving money to get an accurate estimate of the fees involved. Our calculator takes into account Paypal\'s standard fees as well as currency conversion fees, so you can avoid any surprises. Try it out today on onlinetoolsfor.com!"
      />
    <header class="pt-5">
    <div class="container px-lg-5">
        <div class="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div class="m-4 m-lg-5">
                <h1 class="display-5 fw-bold">Paypal Fee Calculator</h1>
            </div>
        </div>
    </div>
</header>
<div class="container px-lg-5">
    <nav aria-label="breadcrumb">
        <ol class="py-1 my-2 breadcrumb">
        <li class="breadcrumb-item"><Link to="/">Home</Link></li>
            <li class="breadcrumb-item"><Link to="/online-calculators">Online Calculators</Link></li>
            <li class="breadcrumb-item active" aria-current="page">Paypal Fee Calculator</li>
        </ol>
    </nav>
</div>
{/* <!-- Page Content--> */}
<section class="pt-4 pb-5">
    <div class="container px-lg-5">
        {/* <!-- Page Features--> */}
        <div class="row gx-lg-5">
            <p class="mb-4">
                Our <b>PayPal Fee Calculator</b> accurately calculates fees for PayPal transactions, saving you time and money. Simply enter the transaction amount, type, country, and currency to get an instant calculation of the total fees charged by PayPal, including both fixed and percentage fees. It's easy, free, and constantly updated to reflect the latest PayPal fees and regulations.
            </p>
            <br /><br />
            <div className="col-md-12">
      <form className='form-global' onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="amount">Amount</label>
          <input
            type="number"
            className="form-control"
            id="amount"
            name="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="currency">Currency</label>
          <select
            className="form-control"
            id="currency"
            name="currency"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option value="INR" selected>INR - Indian Rupee</option>
            <option value="USD">USD - US Dollar</option>
            <option value="EUR">EUR - Euro</option>
            <option value="GBP">GBP - British Pound Sterling</option>
            <option value="JPY">JPY - Japanese Yen</option>
            <option value="CAD">CAD - Canadian Dollar</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="feeRate">Fee Rate (%)</label>
          <input
            type="number"
            className="form-control"
            id="feeRate"
            name="feeRate"
            value={feeRate}
            step="0.01"
            onChange={(e) => setFeeRate(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="fixedFee">Fixed Fee</label>
          <input
            type="number"
            className="form-control"
            id="fixedFee"
            name="fixedFee"
            value={fixedFee}
            step="0.01"
            onChange={(e) => setFixedFee(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary float-end mb-3">
          Calculate
        </button>
        <br />
        <br />
      </form>
      <div className="mt-3">
        <h3>PayPal Fees: <span id="payPalFees">{payPalFee}</span></h3>
        <h3>Total: <span id="total">{totalAmount}</span></h3>
      </div>
    </div>
      </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <p>
                    Welcome to onlinetoolsfor.com, your go-to destination for free online tools. Our PayPal Fee Calculator is a powerful and user-friendly resource that helps you accurately calculate the fees for PayPal transactions. With our advanced SEO techniques, you can easily find our tool and start using it to save time and money.
                </p>
                <h2>Why Is Our PayPal Fee Calculator Important?</h2>
                <p>
                    As a PayPal client, it's basic to comprehend the charges related with every exchange to try not to lose cash. PayPal fees can vary depending on factors such as the type of transaction, currency, and country. Our PayPal Fee Calculator takes all of these factors into account to provide you with an accurate fee calculation.
                </p>
                <h2>How Does Our PayPal Fee Calculator Work?</h2>
                <p>
                    Using our PayPal Fee Calculator is easy. Simply enter the transaction amount, select the transaction type and choose currency. Our calculator will then display the total fees charged by PayPal, including both the fixed and percentage fees.
                </p>
                <h2>Why Choose Our PayPal Fee Calculator?</h2>
                <p>
                    Our PayPal Fee Calculator is a valuable tool for anyone who uses PayPal for transactions. It's accurate, easy to use, and completely free. Besides, our calculator is continually updated to mirror the most late PayPal expenses and rules, guaranteeing that you have the most up-to-date data consistently.
                </p>
                <h3>Conclusion</h3>
                <p>
                    If you're a PayPal user looking to accurately calculate your fees for transactions, our PayPal Fee Calculator is an essential tool. It's easy to use, free, and provides you with the exact fees charged by PayPal, so you can make informed decisions and save money. Try it out today and see the difference it can make!
                </p>
            </div>
        </div>
    </div>
</section>
    </>
  );
};

export default PayPalCalculator;
