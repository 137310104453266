import React, { useState, useEffect, useCallback } from "react";
import cssbeautify from "./cssbeautify"; // Adjust the path based on your project structure
import SEO from "../../../SEO";
import { Link } from "react-router-dom";

const CssBeautifier = () => {

  const [rawCss, setRawCss] = useState("");
  const [beautifiedCss, setBeautifiedCss] = useState("");
  const [indentOption, setIndentOption] = useState("fourspaces");
  const [autoSemicolon, setAutoSemicolon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Use useCallback to memoize the beautify function
  const beautify = useCallback(() => {
    if (!rawCss.trim()) {
      setBeautifiedCss("");
      return;
    }

    setIsLoading(true); // Start loading state
    const indent = getIndentation(indentOption);
    const options = {
      indent: indent,
      autosemicolon: autoSemicolon,
    };

    try {
      const beautified = cssbeautify(rawCss, options);
      setBeautifiedCss(beautified);
    } catch (error) {
      console.error("Error beautifying CSS:", error);
      setBeautifiedCss("Error beautifying CSS. Please check your input.");
    } finally {
      setIsLoading(false); // End loading state
    }
  }, [rawCss, indentOption, autoSemicolon]); // Add dependencies

  useEffect(() => {
    const timer = setTimeout(() => {
      beautify();
    }, 300); // Debounce delay

    return () => clearTimeout(timer);
  }, [beautify]); // Add beautify as a dependency

  const getIndentation = (option) => {
    switch (option) {
      case "fourspaces":
        return "    ";
      case "twospaces":
        return "  ";
      case "tab":
        return "\t";
      default:
        return "    ";
    }
  };

  const copyToClipboard = () => {
    if (beautifiedCss) {
      navigator.clipboard
        .writeText(beautifiedCss)
        .then(() => {
          alert("Beautified CSS copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      alert("Nothing to copy!");
    }
  };

  return (
    <>
      <SEO
        title="CSS Beautifier Tool - Online Tool for Formatting and Beautifying CSS Code | Onlinetoolsfor.com"
        description="Transform your CSS code into a more organized and readable format with the CSS Beautifier Tool from Onlinetoolsfor.com. Our easy-to-use online tool offers advanced formatting options to match your preferences, reducing errors and saving you time. Discover the benefits of our CSS Beautifier Tool and explore other useful online tools on Onlinetoolsfor.com."
      />
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">CSS Beautifier Tool</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/web-tools">Web Tools</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              CSS Beautifier Tool
            </li>
          </ol>
        </nav>
      </div>
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          <div className="row gx-lg-5">
            <p>
              Welcome to the <b>CSS Beautifier Tool</b> page on{" "}
              <b>Onlinetoolsfor.com</b>! With the help of our simple tool, you
              can effortlessly format and beautify your CSS code, conserving you
              time and minimizing errors. With advanced customization options,
              you can create a code style that matches your individual
              preferences. For developers and designers who want to create
              cleaner, organized code, our product is perfect. Plus,
              Onlinetoolsfor.com offers a variety of other useful online tools
              to improve your workflow. Try the CSS Beautifier Tool today and
              experience the difference it can make for your CSS code!
            </p>
            <br />
            <br />
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-12 form-global">
                  <div className="mb-4">
                    <h3>Add Unformatted CSS:</h3>
                    <textarea
                      rows="10"
                      className="form-control"
                      autoFocus={true}
                      spellCheck={false}
                      value={rawCss}
                      onChange={(e) => setRawCss(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <h3>Beautified CSS:</h3>
                    <textarea
                      rows="10"
                      className="form-control"
                      readOnly
                      value={beautifiedCss}
                    />
                  </div>
                  <div className="mb-4">
                    <h3>Options:</h3>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="indent"
                        id="fourspaces"
                        value="fourspaces"
                        checked={indentOption === "fourspaces"}
                        onChange={() => setIndentOption("fourspaces")}
                      />
                      <label className="form-check-label" htmlFor="fourspaces">
                        Indent with 4 spaces
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="indent"
                        id="twospaces"
                        value="twospaces"
                        checked={indentOption === "twospaces"}
                        onChange={() => setIndentOption("twospaces")}
                      />
                      <label className="form-check-label" htmlFor="twospaces">
                        Indent with 2 spaces
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="indent"
                        id="tab"
                        value="tab"
                        checked={indentOption === "tab"}
                        onChange={() => setIndentOption("tab")}
                      />
                      <label className="form-check-label" htmlFor="tab">
                        Indent with tab
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="autosemicolon"
                        checked={autoSemicolon}
                        onChange={() => setAutoSemicolon(!autoSemicolon)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="autosemicolon"
                      >
                        Auto-semicolon insertion
                      </label>
                    </div>
                  </div>
                  <button className="btn btn-primary float-end" onClick={copyToClipboard}>
                    Copy to Clipboard
                  </button>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div class="col-md-12">
                <br />
                <p>
                  In the event that you work as a web designer, know about the
                  significance of clear, coherent CSS code. It not only helps
                  your code easier to handle, but it also accelerates up and
                  enhances the usability of your website. But, what if you have
                  inherited a website or codebase with messy, hard-to-read CSS?
                  This is where a CSS beautifier tool comes in handy.
                </p>
                <p>
                  A CSS beautifier tool is a web-based tool that takes your
                  messy, unorganized CSS code and formats it in a more readable
                  and standardized way. As well as making it more
                  straightforward for you to peruse and understand the code,
                  this likewise makes it simpler for others to deal with the
                  equivalent codebase as you.
                </p>
                <p>
                  The programme reorganises your CSS code to correspond with a
                  set of established guidelines after analysing it. These rules
                  might include subjects like formatting, separation, and
                  indentation. The overall CSS code is neat, organised, and
                  simple to read.
                </p>
                <p>
                  You can freely access and utilize this online tool without any
                  cost. Simply paste your messy CSS code into the tool, click
                  the "Beautify Code" button, and voila! Your code is now clean
                  and organized.
                </p>
                <h2>
                  But, why should you use a CSS beautifier tool? The use of one
                  has the following advantages:
                </h2>
                <ul className='content-listitem'>
                  <li>
                    <b>Increasing readability </b>: An structured CSS file is
                    significantly easier to read and understand than one that is
                    disorganised.
                  </li>
                  <li>
                    <b>Saves time</b>: A CSS beautifier tool can efficiently
                    tidy up your CSS code without the need for manual
                    formatting, saving you valuable time.
                  </li>
                  <li>
                    <b>Consistency</b>: By making sure that all of your CSS code
                    conforms to those same formatting and norms, you may use a
                    CSS beautifier tool to make sure that it does. As an
                    outcome, others can work on the identical codebase with
                    greater ease.
                  </li>
                  <li>
                    <b>Increases efficiency</b>: A clean, organized CSS code is
                    faster and more efficient, which can help improve your
                    website's performance.
                  </li>
                </ul>
                <p>
                  In conclusion, a CSS beautifier tool is a must-have for any
                  website developer who wants to generate neat, organised, and
                  successful CSS code. You can save time, improve efficiency,
                  keep consistency, and enhance readability by utilising one.
                  So, keep a CSS beautifier tool on hand the next time you're
                  fighting with awkward CSS code.
                </p>
                {isLoading && <div className="loading">Beautifying your code...</div>} {/* Loading indicator */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CssBeautifier;
