// Webtools.js
import React from 'react'
import WebtoolsCardParent from '../WebtoolsCardParent';
import SEO from "../SEO";
import { Link } from 'react-router-dom';

function Webtools() {

  return (
    <>
        <SEO
            title="Online Tools - Free Web-Tools for Your Daily Use | OnlineToolsFor.com"
            description="Get access to a variety of free web-based tools for your daily use. Boost your productivity with OnlineToolsFor.com\'s online tools."
        />
      <header className="pt-5">
          <div className="container px-lg-5">
              <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                  <div className="m-4 m-lg-5">
                      <h1 className="display-5 fw-bold">Web Tools</h1>
                      <p className="fs-4">
                          list of all Online Web tools.
                      </p>
                  </div>
              </div>
          </div>
      </header>
      <div className="container px-lg-5 pb-5">
          <nav aria-label="breadcrumb">
              <ol className="py-1 my-2 breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Web Tools</li>
              </ol>
          </nav>
      </div>
      {/* <!-- Page Content--> */}
      <section className="pt-4">
          <div className="container px-lg-5">
              {/* <!-- Page Features--> */}
              <div className="row gx-lg-5">
                  <WebtoolsCardParent />
              </div>
          </div>
      </section>
    </>
  )
}

export default Webtools;
