import React, { useState } from 'react';
import SEO from "../../../SEO";
import { Link } from 'react-router-dom';

const SlugConverter = () => {

  const [text, setText] = useState('');
  const [slug, setSlug] = useState('');

  const getSlug = (string) => {
    const generatedSlug = string.toLowerCase().replace(/[^a-z0-9]+/g, '-');
    setSlug(generatedSlug);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setText(inputValue);
    getSlug(inputValue);
  };

  return (
    <>
    <SEO
        title="Text to SEO-Friendly Slug | OnlineToolsFor.com"
        description="Our free online tool converts any text to a slug that is optimized for search engines. Use this tool to create SEO-friendly URLs for your website."
      />
    <header class="pt-5">
        <div class="container px-lg-5">
            <div class="p-4 p-lg-5 bg-light rounded-3 text-center">
                <div class="m-4 m-lg-5">
                    <h1 class="display-5 fw-bold">Text to Slug</h1>
                </div>
            </div>
        </div>
    </header>
    <div class="container px-lg-5">
        <nav aria-label="breadcrumb">
            <ol class="py-1 my-2 breadcrumb">
            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
            <li class="breadcrumb-item"><Link to="/seo-tools">SEO Tools</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Text to Slug</li>
            </ol>
        </nav>
    </div>
    {/* <!-- Page Content--> */}
    <section class="pt-4 pb-5">
        <div class="container px-lg-5">
          <div className="row gx-lg-5">
            <p className="mb-4">
              Our <b>Text to Slug tool</b> helps you convert any text into an SEO-friendly URL slug for your website. Simply enter your text, and our tool will build a descriptive and keyword-rich slug that will help search engines comprehend the content of your page, increasing the search engine ranking of your website.
            </p>
            <br /><br />
            <div className="col-md-12 form-global">
              <div className="form-group">
                <label htmlFor="entry"><b>Convert this to a slug</b></label>
                <input
                  type="text"
                  className="form-control"
                  id="entry"
                  placeholder="Enter text"
                  value={text}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <br />
            <div className="col-md-12">
                <br />
                <pre style={{ minHeight: '36px' }} className="p-2 bg-light form-control" id="result">
                  {slug}
                </pre>
              </div>
          </div>
          <br />
            <div class="row">
                <div class="col-md-12">
                    <p>
                        Welcome to <b>OnlineToolsFor.com</b>'s <b>Text to Slug</b> Page, where we provide you with a powerful tool to convert any text into a search engine optimized URL slug. This article will show you what a slug is, the reason it is significant for Web optimization, and how to utilize our Text to Slug tool to work on the visibility of your site on search engines.
                    </p>
                    <h2>What is a Slug?</h2>
                    <p>
                        A slug is the component of a URL that identifies a specific page on a website in the context of SEO. It usually consists of a few words that describe the content of the page and is separated by hyphens (-). For the blog post titled "10 Tips for Effective Content Marketing," the corresponding slug would be "10-tips-effective-content-marketing."
                    </p>

                    <h2>Why is a Slug Important for SEO?</h2>
                    <p>
                        A well-optimized slug is essential for improving your website's search engine ranking. When search engines crawl your website, they use the slug to understand the content of the page and determine its relevance to a particular search query. Therefore, having a descriptive and keyword-rich slug can help search engines better understand the content of your page and show it to the right audience.
                    </p>

                    <h2>How to Use OnlineToolsFor.com's Text to Slug Tool</h2>
                    <p>
                        Using our Text to Slug tool is incredibly easy. Simply enter the text you want to convert into a slug, and our tool will automatically generate an optimized URL slug for you. Just copy and paste the slug into your website's URL structure, enabling search engines to grasp your page's content effortlessly.
                    </p>
                    <p>
                        In summary, a well-optimized URL slug is crucial for improving your website's search engine ranking. By using OnlineToolsFor.com's Text to Slug tool, you can easily create descriptive and keyword-rich slugs that will help your website rank higher on search engine results pages. Try our tool today and see the difference it can make in your website's visibility and traffic!
                    </p>
                </div>
            </div>
        </div>
    </section>
    </>
  );
};

export default SlugConverter;
