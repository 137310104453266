import React from 'react'
import { Link } from 'react-router-dom'
import SEO from "../SEO";

function Disclaimer() {

  return (
    <>
        <SEO
            title="Disclaimer - OnlineToolsFor.com"
            description="Read the Disclaimer for OnlineToolsFor.com, your source for internet-based utilities like CSS Generators, Web Tools, SEO Tools, and Online Calculators. Learn about the limitations, responsibilities, and terms associated with using our tools and resources."
        />

      <header className="pt-5">
        <div className="container px-lg-5">
            <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                <div className="m-4 m-lg-5">
                    <h1 className="display-5 fw-bold">Disclaimer</h1>
                </div>
            </div>
        </div>
    </header>
    <div className="container px-lg-5 pb-5">
        <nav aria-label="breadcrumb">
            <ol className="py-1 my-2 breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Disclaimer</li>
            </ol>
        </nav>
    </div>
    {/* <!-- Page Content--> */}
    <section className="pt-4">
        <div className="container px-lg-5">
            {/* <!-- Page Features--> */}
            <div className="row gx-lg-5">
                <div className="col-md-12">
                    <p>
                    Welcome to OnlineToolsFor.com. The information and tools provided on this website are intended for general information purposes only and are designed to assist users in various tasks, such as generating CSS components, optimizing SEO strategies, and performing calculations. While we strive to offer high-quality, accurate, and up-to-date tools and resources, OnlineToolsFor.com makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the tools or information provided.
                    </p>
                    <h2>By using our platform, you acknowledge and agree that:</h2>
                    <ul className='content-listitem'>
                        <li>
                        <b>No Professional Advice:</b> The tools and resources provided are for general use and do not constitute professional, financial, legal, or technical advice. Users are encouraged to seek appropriate advice from qualified professionals before relying on any tool or information offered on this website.
                        </li>
                        <li>
                        <b>Tool Usage:</b> The tools on OnlineToolsFor.com, including CSS Generators, Web Tools, SEO Tools, and Online Calculators, are designed to streamline tasks and enhance efficiency. However, we do not guarantee that the tools will always function as expected or meet your specific requirements.
                        </li>
                        <li>
                        <b>Third-Party Links:</b> Our platform may contain links to third-party websites for your convenience. We have no control over the content, privacy policies, or practices of these third-party websites and assume no responsibility for any damages or losses incurred from using them.
                        </li>
                        <li>
                        <b>Limitation of Liability:</b> OnlineToolsFor.com shall not be held liable for any loss or damage arising from the use of our website, tools, or any external links. This includes, but is not limited to, indirect or consequential loss, loss of data, or damage to your software or hardware.
                        </li>
                        <li>
                        <b>Modifications:</b> We reserve the right to modify, update, or discontinue any tool or service on this platform at any time without notice.
                        </li>
                        <li>
                        <b>No Guarantee of Accuracy:</b> While we work hard to maintain the accuracy and functionality of our tools, we cannot guarantee that all content will be free from errors. Users are responsible for verifying the accuracy and suitability of the tools for their needs.
                        </li>
                    </ul>
                    <p>
                    By accessing and using OnlineToolsFor.com, you agree to this disclaimer. If you do not agree with any part of this disclaimer, please refrain from using our platform.
                    </p>
                    <p>
                    For any questions or concerns regarding this disclaimer, please <Link to="/contact"><b>Contact</b></Link> us.
                    </p>

                    
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Disclaimer