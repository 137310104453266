import React, { useMemo } from "react";

const ShareButtons = () => {
  // Memoizing URL and title for sharing
  const { url, title } = useMemo(() => {
    return {
      url: encodeURIComponent(window.location.href),
      title: encodeURIComponent(document.title),
    };
  }, []);

  // Function to open a share window
  const openShareWindow = (shareUrl) => {
    window.open(shareUrl, "_blank", "noopener,noreferrer");
  };

  // Share functions for each platform
  const shareFacebook = () => {
    openShareWindow(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
  };

  const shareX = () => {
    openShareWindow(`https://twitter.com/intent/tweet?url=${url}&text=${title}`); // X (Twitter) sharing URL
  };

  const shareWhatsApp = () => {
    openShareWindow(`https://api.whatsapp.com/send?text=${title}%20${url}`);
  };

  return (
    <div className="share-buttons">
      {/* Facebook */}
      <button
        className="share-btn facebook"
        onClick={shareFacebook}
        aria-label="Share on Facebook"
      >
        <i className="fab fa-facebook-f"></i>
      </button>

      {/* X (formerly Twitter) */}
      <button
        className="share-btn x"
        onClick={shareX}
        aria-label="Share on X"
      >
        <i className="fab fa-x-twitter"></i>
      </button>

      {/* WhatsApp */}
      <button
        className="share-btn whatsapp"
        onClick={shareWhatsApp}
        aria-label="Share on WhatsApp"
      >
        <i className="fab fa-whatsapp"></i>
      </button>
    </div>
  );
};

export default ShareButtons;
