// src/pages/online-calculators/mutual-fund-calculator/MutualFundCalculator.js

import React, { useState } from "react";
import SEO from "../../../SEO";
import { Link } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
// import './MutualFundCalculator.css'; // Optional: Create this file for custom styles

const MutualFundCalculator = () => {
  const [investmentType, setInvestmentType] = useState("lumpsum"); // 'lumpsum' or 'sip'
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [monthlyInvestment, setMonthlyInvestment] = useState("");
  const [annualReturn, setAnnualReturn] = useState("");
  const [years, setYears] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState("");

  const handleInvestmentTypeChange = (e) => {
    setInvestmentType(e.target.value);
    // Reset relevant fields when investment type changes
    setInvestmentAmount("");
    setMonthlyInvestment("");
    setResult(null);
    setError("");
  };

  const calculateReturns = (e) => {
    e.preventDefault();

    // Input validation
    if (annualReturn <= 0 || years <= 0) {
      setError(
        "Please enter positive values for annual return and investment duration."
      );
      setResult(null);
      return;
    }

    if (investmentType === "lumpsum") {
      if (investmentAmount <= 0) {
        setError("Please enter a positive investment amount.");
        setResult(null);
        return;
      }
    } else if (investmentType === "sip") {
      if (monthlyInvestment <= 0) {
        setError("Please enter a positive monthly investment amount.");
        setResult(null);
        return;
      }
    }

    setError("");

    let investedAmount = 0;
    let futureValue = 0;

    const rate = annualReturn / 100;
    const n = years;

    if (investmentType === "lumpsum") {
      investedAmount = parseFloat(investmentAmount);
      futureValue = investedAmount * Math.pow(1 + rate, n);
    } else if (investmentType === "sip") {
      const monthlyRate = rate / 12;
      const totalMonths = years * 12;
      const monthlyInvest = parseFloat(monthlyInvestment);
      investedAmount = monthlyInvest * totalMonths;
      futureValue =
        monthlyInvest *
        ((Math.pow(1 + monthlyRate, totalMonths) - 1) / monthlyRate) *
        (1 + monthlyRate);
    }

    const estimatedReturns = futureValue - investedAmount;

    setResult({
      investedAmount: investedAmount.toFixed(2),
      estimatedReturns: estimatedReturns.toFixed(2),
      totalValue: futureValue.toFixed(2),
    });
  };

  const resetForm = () => {
    setInvestmentType("lumpsum");
    setInvestmentAmount("");
    setMonthlyInvestment("");
    setAnnualReturn("");
    setYears("");
    setResult(null);
    setError("");
  };

  return (
    <>
      <SEO
        title="Mutual Fund Calculator - Plan Your Investments Effectively | onlinetoolsfor.com"
        description="Use our Mutual Fund Calculator to estimate the future value of your investments. Choose between Lumpsum and SIP options to plan your financial growth accurately."
      />
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">Mutual Fund Calculator</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/online-calculators">Online Calculators</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Mutual Fund Calculator
            </li>
          </ol>
        </nav>
      </div>
      {/* <!-- Page Content--> */}
        <section className="pt-4 pb-5">
            <div className="container px-lg-5">
                {/* <!-- Page Features--> */}
                <div className="row gx-lg-5">
                    <p className="mb-4">
                        Investing in mutual funds is a strategic way to build wealth over
                        time. However, understanding the potential growth of your
                        investments can be challenging. Our user-friendly{" "}
                        <b>Mutual Fund Calculator</b> simplifies this process by providing
                        accurate estimations, helping you make informed financial
                        decisions. Whether you're opting for a one-time <b>Lumpsum</b>{" "}
                        investment or a <b>SIP (Systematic Investment Plan)</b>, our
                        calculator is designed to cater to your unique investment needs.
                    </p>
                    <br />
                    <br />
                </div>
                <div className="col-md-8 mx-auto">
                    {/* Calculator Form */}
                    <div className="skb-padding p-4">
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form className="form-global" onSubmit={calculateReturns}>
                        {/* Investment Type Selection */}
                        <Form.Group className="mb-3" controlId="investmentType">
                            <Form.Label>Investment Type</Form.Label>
                            <Form.Select
                            value={investmentType}
                            onChange={handleInvestmentTypeChange}
                            >
                            <option value="lumpsum">Lumpsum</option>
                            <option value="sip">
                                SIP (Systematic Investment Plan)
                            </option>
                            </Form.Select>
                        </Form.Group>

                        {/* Conditional Rendering Based on Investment Type */}
                        {investmentType === "lumpsum" ? (
                            <Form.Group className="mb-3" controlId="investmentAmount">
                            <Form.Label>Investment Amount (₹)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter your investment amount"
                                value={investmentAmount}
                                onChange={(e) => setInvestmentAmount(e.target.value)}
                                required
                            />
                            </Form.Group>
                        ) : (
                            <Form.Group className="mb-3" controlId="monthlyInvestment">
                            <Form.Label>Monthly Investment (₹)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter your monthly investment amount"
                                value={monthlyInvestment}
                                onChange={(e) => setMonthlyInvestment(e.target.value)}
                                required
                            />
                            </Form.Group>
                        )}

                        <Form.Group className="mb-3" controlId="annualReturn">
                            <Form.Label>Expected Annual Return (%)</Form.Label>
                            <Form.Control
                            type="number"
                            placeholder="Enter expected annual return"
                            value={annualReturn}
                            onChange={(e) => setAnnualReturn(e.target.value)}
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="years">
                            <Form.Label>Investment Duration (Years)</Form.Label>
                            <Form.Control
                            type="number"
                            placeholder="Enter investment duration in years"
                            value={years}
                            onChange={(e) => setYears(e.target.value)}
                            required
                            />
                        </Form.Group>

                        <div className="d-flex justify-content-between">
                            <Button variant="primary" type="submit">
                            Calculate
                            </Button>
                            <Button variant="secondary" onClick={resetForm}>
                            Reset
                            </Button>
                        </div>
                        </Form>

                        {result && (
                        <div className="mt-4">
                            <h4>Investment Summary:</h4>
                            <ul className="list-group">
                            <li className="list-group-item">
                                <strong>Invested Amount:</strong> ₹
                                {result.investedAmount}
                            </li>
                            <li className="list-group-item">
                                <strong>Estimated Returns:</strong> ₹
                                {result.estimatedReturns}
                            </li>
                            <li className="list-group-item">
                                <strong>Total Value:</strong> ₹{result.totalValue}
                            </li>
                            </ul>
                        </div>
                        )}
                    </div>
                </div>
                {/* Informational Content */}
                <br />
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <h2>What is a Mutual Fund Calculator?</h2>
                        <p>
                        A <b>Mutual Fund Calculator</b> is a powerful online tool
                        designed to help investors estimate the future value of their
                        mutual fund investments. By inputting key parameters such as
                        the investment type, amount, expected annual return, and
                        investment duration, users can project the potential growth of
                        their investments over time. Whether you're considering a
                        one-time investment or opting for a systematic investment plan
                        (SIP), our calculator provides accurate and reliable
                        estimations to guide your financial decisions.
                        </p>

                        <h2>How to Use the Mutual Fund Calculator</h2>
                        <p>
                        Utilizing our Mutual Fund Calculator is straightforward.
                        Follow these simple steps to calculate the future value of
                        your investments:
                        </p>

                        <ul className="content-listitem">
                        <li>
                            <strong>Select Investment Type:</strong> Choose between{" "}
                            <b>Lumpsum</b> or <b>SIP</b> based on your investment
                            strategy.
                        </li>
                        <li>
                            <strong>Enter Investment Details:</strong> Input the
                            investment amount or monthly investment, expected annual
                            return rate, and investment duration.
                        </li>
                        <li>
                            <strong>Calculate:</strong> Click the "Calculate" button to
                            view your investment's future value.
                        </li>
                        <li>
                            <strong>Review Results:</strong> Analyze the invested
                            amount, estimated returns, and total value to make informed
                            financial decisions.
                        </li>
                        <li>
                            <strong>Reset:</strong> Use the "Reset" button to clear all
                            inputs and start a new calculation.
                        </li>
                        </ul>

                        <h3>Understanding the Results</h3>
                        <p>
                        After performing the calculations, the Mutual Fund Calculator
                        provides a comprehensive summary of your investment:
                        </p>
                        <ul className="content-listitem">
                        <li>
                            <strong>Invested Amount:</strong> The total amount you have
                            invested over the specified period.
                        </li>
                        <li>
                            <strong>Estimated Returns:</strong> The profit earned from
                            your investment based on the expected annual return rate.
                        </li>
                        <li>
                            <strong>Total Value:</strong> The sum of your invested
                            amount and the estimated returns, representing the total
                            value of your investment after the investment duration.
                        </li>
                        </ul>

                        <h3>Benefits of Using Our Mutual Fund Calculator</h3>
                        <ul className="content-listitem">
                        <li>
                            <strong>Accurate Projections:</strong> Provides reliable
                            estimations to help you plan your financial future
                            effectively.
                        </li>
                        <li>
                            <strong>Decision Making:</strong> Assists in choosing
                            between different investment strategies like Lumpsum and
                            SIP.
                        </li>
                        <li>
                            <strong>Financial Planning:</strong> Helps set realistic
                            financial goals and timelines.
                        </li>
                        <li>
                            <strong>User-Friendly Interface:</strong> Ensures a seamless
                            experience with an intuitive design.
                        </li>
                        </ul>

                        <h2>Why Choose onlinetoolsfor.com?</h2>
                        <p>
                        At <b>onlinetoolsfor.com</b>, we are committed to providing
                        our users with high-quality online tools that simplify complex
                        tasks. Our Mutual Fund Calculator is designed with accuracy,
                        efficiency, and user experience in mind, ensuring that you
                        have the resources you need to make informed financial
                        decisions. Whether you're a seasoned investor or just starting
                        your investment journey, our tools are tailored to meet your
                        unique needs.
                        </p>

                        <h2>Conclusion</h2>
                        <p>
                        Planning your financial future is essential, and understanding
                        the potential growth of your investments plays a crucial role
                        in this process. Our Mutual Fund Calculator offers a simple
                        yet effective way to estimate the future value of your
                        investments, whether you're considering a one-time Lumpsum
                        investment or opting for a systematic investment plan (SIP).
                        Empower yourself with accurate data and make informed
                        decisions to achieve your financial goals with confidence.
                        Visit <b>onlinetoolsfor.com</b> today and take the first step
                        towards securing your financial future.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
};

export default MutualFundCalculator;
