import React, { useState, useMemo } from "react";
import SEO from "../../../SEO";
import { Link } from "react-router-dom";
import { Line } from 'react-chartjs-2'; 
import { Chart, registerables } from 'chart.js';

// Register all the required components for Chart.js
Chart.register(...registerables);

const BMICalculator = () => {
  const [form, setForm] = useState({
    height: "",
    weight: "",
    age: "",
    gender: "male",
    unit: "metric",
  });

  const [results, setResults] = useState({
    bmi: null,
    idealWeight: null,
    weightChange: null,
    classification: null,
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const calculateBMI = () => {
    const { height, weight, age, unit } = form;

    if (!height || !weight || !age) {
      setError("Please enter all fields.");
      return;
    }

    const h = unit === "metric" ? height / 100 : height * 0.0254; // Convert height to meters
    const w = unit === "metric" ? weight : weight * 0.453592; // Convert weight to kg

    if (h <= 0 || w <= 0) {
      setError("Height and weight must be positive numbers.");
      return;
    }

    const bmiValue = (w / (h * h)).toFixed(2);
    const idealWeightValue = (22 * (h * h)).toFixed(2);
    const change = (idealWeightValue - w).toFixed(2);
    
    let classification = "";
    let color = "";

    // Determine BMI classification and color
    if (bmiValue < 16) {
      classification = "Severe Thinness";
      color = "#ff9999"; // Light red
    } else if (bmiValue < 17) {
      classification = "Moderate Thinness";
      color = "#ffcccc"; // Light pink
    } else if (bmiValue < 18.5) {
      classification = "Mild Thinness";
      color = "#ffdfdf"; // Lighter pink
    } else if (bmiValue < 25) {
      classification = "Normal";
      color = "#66b3ff"; // Light blue
    } else if (bmiValue < 30) {
      classification = "Overweight";
      color = "#99ff99"; // Light green
    } else if (bmiValue < 35) {
      classification = "Obese Class I";
      color = "#ffcc99"; // Light orange
    } else if (bmiValue < 40) {
      classification = "Obese Class II";
      color = "#ffb3b3"; // Medium red
    } else {
      classification = "Obese Class III";
      color = "#ff6666"; // Dark red
    }

    setResults({
      bmi: bmiValue,
      idealWeight: idealWeightValue,
      weightChange: change,
      classification,
      color,
    });

    setError("");
  };

  const clearResults = () => {
    setForm({
      height: "",
      weight: "",
      age: "",
      gender: "male",
      unit: "metric",
    });
    setResults({
      bmi: null,
      idealWeight: null,
      weightChange: null,
      classification: null,
      color: null,
    });
    setError("");
  };

  const chartData = useMemo(() => {
    const labels = ['Underweight', 'Normal', 'Overweight', 'Obesity'];
    const data = {
      labels,
      datasets: [
        {
          label: 'BMI Classification',
          data: [18.5, 24.9, 29.9, 40],
          backgroundColor: ['#ff9999', '#66b3ff', '#99ff99', '#ffcc99'],
          borderWidth: 1,
          fill: true,
        },
      ],
    };

    if (results.bmi) {
      data.datasets.push({
        label: 'Your BMI',
        data: Array(labels.length).fill(results.bmi),
        borderColor: 'red',
        borderWidth: 2,
        pointRadius: 0,
        fill: false,
        borderDash: [5, 5],
      });
    }

    return data;
  }, [results.bmi]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'BMI Value',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
  };

  return (
    <>
      <SEO
        title="BMI Calculator - Calculate Your Body Mass Index | onlinetoolsfor.com"
        description="Advanced BMI Calculator to calculate your BMI and find out your ideal weight. Enter your height and weight, select your age and gender, and get accurate results!"
      />
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">BMI Calculator</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/online-calculators">Online Calculators</Link></li>
            <li className="breadcrumb-item active" aria-current="page">BMI Calculator</li>
          </ol>
        </nav>
      </div>
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          <div className="row gx-lg-5">
            <div className="col-md-6">
              <div className="mx-auto">
                <form id="bmi-form" className="form-global">
                  <div className="form-group mb-3">
                    <label htmlFor="height" className="form-label"><b>Height:</b></label>
                    <input
                      type="number"
                      className="form-control"
                      id="height"
                      placeholder={form.unit === "metric" ? "Enter height in cm" : "Enter height in inches"}
                      value={form.height}
                      onChange={handleChange}
                      min="0"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="weight" className="form-label"><b>Weight:</b></label>
                    <input
                      type="number"
                      className="form-control"
                      id="weight"
                      placeholder={form.unit === "metric" ? "Enter weight in kg" : "Enter weight in lbs"}
                      value={form.weight}
                      onChange={handleChange}
                      min="0"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="age" className="form-label"><b>Age:</b></label>
                    <input
                      type="number"
                      className="form-control"
                      id="age"
                      placeholder="Enter your age"
                      value={form.age}
                      onChange={handleChange}
                      min="0"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="gender" className="form-label"><b>Gender:</b></label>
                    <select
                      className="form-select"
                      id="gender"
                      value={form.gender}
                      onChange={handleChange}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="unit" className="form-label"><b>Unit:</b></label>
                    <select
                      className="form-select"
                      id="unit"
                      value={form.unit}
                      onChange={handleChange}
                    >
                      <option value="metric">Metric (cm, kg)</option>
                      <option value="imperial">Imperial (inches, lbs)</option>
                    </select>
                  </div>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <div className="form-group mb-3 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      onClick={calculateBMI}
                    >
                      Calculate
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={clearResults}
                    >
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              {results.bmi && (
                <div className="result-box p-4 mb-5 rounded-4" style={{ backgroundColor: results.color }}>
                  <h4>Your BMI: <strong>{results.bmi}</strong></h4>
                  <h5>Ideal Weight: <strong>{results.idealWeight} kg</strong></h5>
                  <h5>Weight Change Needed: <strong>{results.weightChange} kg</strong></h5>
                  <h5>Classification: <strong>{results.classification}</strong></h5>
                </div>
              )}
              <div style={{ height: '300px' }}>
                <Line data={chartData} options={chartOptions} />
              </div>
            </div>
          </div>
          {/* <!-- Explanatory Content--> */}
          <div className="row gx-lg-5 mt-5">
            <div className="col-md-12">
              <div className="mx-auto">
                <h2>Understanding BMI Calculators</h2>
                <p>
                  A Body Mass Index (BMI) calculator is a simple tool that helps you assess whether your weight is in a healthy range relative to your height. By inputting your height and weight, the calculator provides a numerical value that categorizes your weight status, such as underweight, normal weight, overweight, or obese.
                </p>

                <h2>How to Use Our BMI Calculator</h2>
                <p>Using our BMI calculator is straightforward! Follow these simple steps to calculate your BMI:</p>
                <ul className="content-listitem">
                  <li>
                    <b>Step 1: Enter Your Height</b>
                    Begin by entering your height. You can choose between metric units (centimeters) or imperial units (inches) based on your preference.
                  </li>
                  <li>
                    <b>Step 2: Enter Your Weight</b>
                    Next, input your weight. Again, you can select metric units (kilograms) or imperial units (pounds) to match your height input.
                  </li>
                  <li>
                    <b>Step 3: Enter Your Age</b>
                    Providing your age helps in offering a more personalized assessment, as BMI interpretations can vary with age.
                  </li>
                  <li>
                    <b>Step 4: Select Your Gender</b>
                    While BMI is a general indicator, selecting your gender can provide additional context for your results.
                  </li>
                  <li>
                    <b>Step 5: Choose Your Preferred Unit System</b>
                    Decide whether you want to use the metric system or the imperial system for your measurements.
                  </li>
                  <li>
                    <b>Step 6: Click Calculate</b>
                    After entering all the required information, click the "Calculate" button to view your BMI, ideal weight, and the weight change needed to reach your ideal BMI.
                  </li>
                </ul>

                <h2>Advantages of Our BMI Calculator</h2>
                <p>
                  Our BMI calculator offers several benefits that make it an essential tool for maintaining a healthy lifestyle:
                </p>

                <h3>User-Friendly Interface</h3>
                <p>
                  We designed our BMI calculator with simplicity in mind. Its intuitive interface ensures that users of all ages and technical backgrounds can easily perform calculations without any hassle.
                </p>

                <h3>Accurate and Reliable Results</h3>
                <p>
                  Precision is paramount when it comes to health assessments. Our BMI calculator uses standard formulas to provide accurate results, helping you make informed decisions about your health and fitness.
                </p>

                <h3>Comprehensive Insights</h3>
                <p>
                  Beyond just calculating BMI, our tool also provides insights into your ideal weight and the necessary weight change to achieve a healthy BMI, offering a more holistic view of your health status.
                </p>

                <h3>Time-Saving Tool</h3>
                <p>
                  Say goodbye to manual calculations and complex formulas. Our BMI calculator delivers instant results, saving you valuable time and effort.
                </p>

                <h2>Conclusion</h2>
                <p>
                  Maintaining a healthy weight is crucial for overall well-being, and understanding your BMI is a significant step in that journey. Our user-friendly BMI calculator simplifies this process, providing you with accurate and comprehensive insights into your health. Whether you're monitoring your weight, planning a fitness regimen, or seeking to understand your health better, our BMI calculator is here to assist you every step of the way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BMICalculator;
