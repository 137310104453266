// Items.js
import React from "react";

const Items = ({ invoiceData, setInvoiceData, addItem, removeItem }) => {
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...invoiceData.items];
    updatedItems[index][field] = field === "description" ? value : parseFloat(value) || 0;
    setInvoiceData({ ...invoiceData, items: updatedItems });
  };

  return (
    <div className="section form-global">
      <label class="form-label"><b>Items:</b></label>
      {invoiceData.items.map((item, index) => (
        <div key={index} className="item">
          <input
            className="form-control"
            type="text"
            placeholder="Description"
            value={item.description}
            onChange={(e) => handleItemChange(index, "description", e.target.value)}
          />
          <input
            className="form-control"
            type="number"
            placeholder="Quantity"
            value={item.quantity}
            min="0"
            onChange={(e) => handleItemChange(index, "quantity", e.target.value)}
          />
          <input
            className="form-control"
            type="number"
            placeholder="Unit Price"
            value={item.unitPrice}
            min="0"
            onChange={(e) => handleItemChange(index, "unitPrice", e.target.value)}
          />
          <button onClick={() => removeItem(index)}>Remove</button>
        </div>
      ))}
      <button onClick={addItem}>Add Item</button>
    </div>
  );
};

export default Items;
