// BusinessInfo.js
import React from "react";

const BusinessInfo = ({ invoiceData, setInvoiceData }) => {
  const handleFileChange = (e, field) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setInvoiceData((prevData) => ({
          ...prevData,
          business: { ...prevData.business, [field]: base64String },
        }));
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className="section form-global">
      {/* <h3>Business Information</h3> */}
      <label class="form-label"><b>Business Information:</b></label>
      <input
        className="form-control"
        type="text"
        placeholder="Business Name"
        value={invoiceData.business.name}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            business: { ...invoiceData.business, name: e.target.value },
          })
        }
      />
      <input
        className="form-control"
        type="text"
        placeholder="Business Address"
        value={invoiceData.business.address}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            business: { ...invoiceData.business, address: e.target.value },
          })
        }
      />
      <input
        className="form-control"
        type="text"
        placeholder="Phone"
        value={invoiceData.business.phone}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            business: { ...invoiceData.business, phone: e.target.value },
          })
        }
      />
      <input
        className="form-control"
        type="email"
        placeholder="Email"
        value={invoiceData.business.email}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            business: { ...invoiceData.business, email: e.target.value },
          })
        }
      />
      <label htmlFor="logoUpload" class="form-label"><b>Upload Logo (PNG, JPEG):</b></label>
      <input
        id="logoUpload"
        type="file"
        accept="image/png, image/jpeg"
        onChange={(e) => handleFileChange(e, "logoBase64")}
      />
    </div>
  );
};

export default BusinessInfo;
