import React, { useState, useEffect, useRef } from 'react';
import './box-shadow.module.css';
import SEO from "../../../SEO";
import { Link } from 'react-router-dom';
import Prism from "prismjs";
import "prismjs/themes/prism.css"; // Import Prism CSS theme
import "prismjs/components/prism-css"; // Import CSS syntax highlighting

const BoxShadowGenerator = () => {
  // State variables for box-shadow properties
  const [x, setX] = useState(5);
  const [y, setY] = useState(5);
  const [z, setZ] = useState(5);
  const [statusCheck, setStatusCheck] = useState(false); // Changed to boolean
  const [m, setM] = useState(0);
  const [s, setS] = useState(1);
  const [color, setColor] = useState('#777777');

  // State for the generated CSS code
  const [cssCode, setCssCode] = useState('');

  // Ref for the code block
  const codeRef = useRef(null);

  // Function to update box-shadow and CSS code
  const updateBoxShadow = () => {
    // Convert hex color to RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Construct the box-shadow CSS value
    const boxShadowValue = `${statusCheck ? 'inset ' : ''}${x}px ${y}px ${z}px ${m}px rgba(${r}, ${g}, ${b}, ${s})`;

    // Generate the complete CSS code with vendor prefixes
    const generatedCss = `
box-shadow: ${boxShadowValue};
-webkit-box-shadow: ${boxShadowValue};
-moz-box-shadow: ${boxShadowValue};
    `.trim();

    setCssCode(generatedCss);
  };

  // Function to handle copying CSS code to clipboard
  const copyCodeToClipboard = () => {
    if (codeRef.current) {
      navigator.clipboard.writeText(cssCode)
        .then(() => {
          // Optionally, provide feedback to the user
          alert("CSS code copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    }
  };

  // Update box-shadow whenever dependencies change
  useEffect(() => {
    updateBoxShadow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x, y, z, m, color, statusCheck, s]);

  // Highlight the code whenever cssCode changes
  useEffect(() => {
    Prism.highlightAll();
  }, [cssCode]);

  // Style for the box generator
  const boxGeneratorStyle = {
    boxShadow: statusCheck
      ? `inset ${x}px ${y}px ${z}px ${m}px rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, ${s})`
      : `${x}px ${y}px ${z}px ${m}px rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, ${s})`,
    width: "200px",
    height: "200px",
    backgroundColor: "#ffffff",
    margin: "0 auto",
    transition: "box-shadow 0.3s ease",
  };

  return (
    <>
      <SEO
        title="CSS Box Shadow Generator - Create Custom Shadow Styles | OnlineToolsFor.com"
        description="Easily create custom CSS box shadows with our free online tool. Add depth and dimension to your web design projects with a range of shadow styles and effects."
      />
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">CSS Box Shadow Generator</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/css-generators">CSS Generators</Link></li>
            <li className="breadcrumb-item active" aria-current="page">CSS Box Shadow Generator</li>
          </ol>
        </nav>
      </div>
      {/* <!-- Page Content--> */}
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          {/* <!-- Page Features--> */}
          <div className="row gx-lg-5">
            <p>
              <b>OnlineToolsFor.com</b>'s <b>CSS box shadow generator</b> simplifies adding box shadows to web pages. Generate code with horizontal/vertical offsets, blur radius, and color. Compatible with all CSS3-supporting browsers.
            </p>
            <br /><br /><br /><br />
            <div className="col-md-6">
              <div id="boxGenerator" style={boxGeneratorStyle}></div>
            </div>
            <div className="col-md-6">
              <div className="boxshadow-options">
                <ul className="list-group">
                  <li className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-md-6"><span>Shadow Color</span></div>
                      <div className="col-md-6">
                        <input
                          type="color"
                          id="color"
                          onChange={(e) => setColor(e.target.value)}
                          value={color}
                          className="form-control form-control-color"
                          title="Choose your color"
                        />
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-md-6"><span>Shift Right</span></div>
                      <div className="col-md-6">
                        <input
                          type="range"
                          className="form-range"
                          id="shiftRight"
                          onChange={(e) => setX(parseInt(e.target.value))}
                          value={x}
                          min="-50"
                          max="50"
                        />
                        <span>{x}px</span>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-md-6"><span>Shift Down</span></div>
                      <div className="col-md-6">
                        <input
                          type="range"
                          className="form-range"
                          id="shiftDown"
                          onChange={(e) => setY(parseInt(e.target.value))}
                          value={y}
                          min="-50"
                          max="50"
                        />
                        <span>{y}px</span>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-md-6"><span>Blur</span></div>
                      <div className="col-md-6">
                        <input
                          type="range"
                          className="form-range"
                          id="blur"
                          onChange={(e) => setZ(parseInt(e.target.value))}
                          value={z}
                          min="0"
                          max="50"
                        />
                        <span>{z}px</span>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-md-6"><span>Spread</span></div>
                      <div className="col-md-6">
                        <input
                          type="range"
                          className="form-range"
                          id="spread"
                          onChange={(e) => setM(parseInt(e.target.value))}
                          value={m}
                          min="-30"
                          max="50"
                        />
                        <span>{m}px</span>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-md-6"><span>Opacity</span></div>
                      <div className="col-md-6">
                        <input
                          type="range"
                          className="form-range"
                          id="opacity"
                          onChange={(e) => setS(parseFloat(e.target.value))}
                          value={s}
                          min="0.00"
                          max="1.0"
                          step="0.01"
                        />
                        <span>{s}</span>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-md-6"><span>Inset</span></div>
                      <div className="col-md-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="check"
                            onChange={(e) => setStatusCheck(e.target.checked)}
                            checked={statusCheck}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <h4>CSS Code:</h4>
              <pre>
                <code className="language-css" ref={codeRef}>
                  {cssCode}
                </code>
              </pre>
              <button
                className='btn btn-primary float-end'
                onClick={copyCodeToClipboard}
              >
                Copy Code
              </button>
            </div>
          </div>
          <br /><br />
          <div className="row">
            <div className="col-md-12">
              <h2>CSS Box Shadow Generator: Create Beautiful Shadows for Your Web Elements</h2>
              <p>
                Are you tired of flat, boring web designs that lack depth and dimension? Add visual interest and depth to your web elements with CSS box shadows. CSS box shadows are a basic method for adding depth and dimension to your web components, and they are not difficult to modify to accommodate your particular necessities. With our CSS box shadow generator, you can create beautiful, custom shadows for your web elements with just a few clicks.
              </p>
              <h2>What is a CSS Box Shadow?</h2>
              <p>
                A CSS box shadow is a visual effect that adds depth and dimension to web elements. By applying a shadow to the outer portion of an element, it is possible to generate a visual effect that simulates either an elevated or recessed surface. CSS box shadows are commonly used on buttons, cards, and other web elements to make them stand out and give them a more polished look.
              </p>
              <h2>Why Use a CSS Box Shadow Generator?</h2>
              <p>
                While CSS box shadows are easy to create, manually adjusting the values to achieve the desired effect can be time-consuming and frustrating. That's where our CSS box shadow generator comes in. With our tool, you can create custom box shadows quickly and easily, without any coding knowledge required. Essentially change the values in the generator until you accomplish the ideal effect, and then copy and paste the created CSS code into your project.
              </p>
              <h2>How to Use Our CSS Box Shadow Generator</h2>
              <h3>Using our CSS box shadow generator is simple. Here's how to get started:</h3>
              <ul className='content-listitem'>
                <li>Pick the type of shadow you need to create. Our generator offers options for box shadow, inset shadow, and more.</li>
                <li>Adjust the values to create the desired effect. Use the sliders and input fields to adjust the shadow's color, size, blur, spread, and position until you achieve the desired effect.</li>
                <li>Preview your shadow. As you change the values, you can see a preview of your shadow in real-time to ensure that it looks perfect.</li>
                <li>Copy the generated CSS code. When you're satisfied with your shadow, click the "Copy Code" button to transfer the created CSS code to your clipboard. The produced code may then be copied and pasted into your desired project, and you're done!</li>
              </ul>
              <h2>Conclusion</h2>
              <p>
                With our CSS box shadow generator, adding depth and dimension to your web elements has never been easier. Our tool is simple to use and produces high-quality, customizable shadows that will make your web elements stand out. Whether you're a web designer or a developer, our CSS box shadow generator is an essential tool to have in your arsenal. Try it out today and see the difference it can make for your web design projects!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BoxShadowGenerator;
