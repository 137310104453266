// BorderRadiusControl.js
import React, { useEffect, useState, useRef } from 'react';
import './border-radius.css';
import SEO from "../../../SEO";
import { Link } from 'react-router-dom';
import Prism from "prismjs";
import "prismjs/themes/prism.css"; // You can choose other themes like prism-okaidia.css, prism-tomorrow.css, etc.
import "prismjs/components/prism-css"; // Ensure CSS syntax is loaded

const BorderRadiusControl = () => {
  // State for individual corner radii and all corners
  const [radiusValues, setRadiusValues] = useState({
    radius1: 25,
    radius2: 25,
    radius3: 25,
    radius4: 25,
    radiusAll: 25,
  });

  // State for generated CSS code
  const [cssCode, setCssCode] = useState('');

  // Refs for the code block and the preview element
  const codeRef = useRef(null);
  const previewRef = useRef(null);

  // Effect to initialize default values and highlight code on mount
  useEffect(() => {
    setDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect to update CSS code and preview whenever radiusValues change
  useEffect(() => {
    updateCssCode();
    updatePreview();
    Prism.highlightElement(codeRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radiusValues]);

  // Function to set default radius values
  const setDefaults = () => {
    const defaultRadius = 25;
    setRadiusValues({
      radius1: defaultRadius,
      radius2: defaultRadius,
      radius3: defaultRadius,
      radius4: defaultRadius,
      radiusAll: defaultRadius,
    });
  };

  // Function to handle updates to radius values
  const updateRadiusValues = (corner, value) => {
    const numericValue = parseInt(value, 10);
    if (isNaN(numericValue)) return; // Prevent NaN

    if (corner === 'radiusAll') {
      setRadiusValues({
        radius1: numericValue,
        radius2: numericValue,
        radius3: numericValue,
        radius4: numericValue,
        radiusAll: numericValue,
      });
    } else {
      setRadiusValues({
        ...radiusValues,
        [corner]: numericValue,
      });
    }
  };

  // Function to generate CSS code
  const updateCssCode = () => {
    const { radius1, radius2, radius3, radius4 } = radiusValues;

    // Construct the border-radius CSS value without the slash
    const borderRadiusValue = `${radius1}px ${radius2}px ${radius4}px ${radius3}px`;

    // Generate the complete CSS code with vendor prefixes
    const generatedCss = `
border-radius: ${borderRadiusValue};
-webkit-border-radius: ${borderRadiusValue};
-moz-border-radius: ${borderRadiusValue};
    `.trim();

    setCssCode(generatedCss);
  };

  // Function to update the preview element's border-radius
  const updatePreview = () => {
    const { radius1, radius2, radius3, radius4 } = radiusValues;
    if (previewRef.current) {
      previewRef.current.style.borderRadius = `${radius1}px ${radius2}px ${radius4}px ${radius3}px`;
    }
  };

  // Function to handle copying CSS code to clipboard
  const handleCopyCode = () => {
    navigator.clipboard.writeText(cssCode)
      .then(() => {
        // Provide user feedback (you can enhance this with toast notifications)
        alert("CSS code copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        alert("Failed to copy CSS code. Please try again.");
      });
  };

  return (
    <>
      <SEO
        title="CSS Border Radius Generator - Free Border Radius Generator Tool | OnlineToolsFor.com"
        description="Easily create custom CSS border radius with our free CSS border radius generator. Add rounded corners and custom styles to your web design projects with ease."
      />
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">CSS Border Radius Generator</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/css-generators">CSS Generators</Link></li>
            <li className="breadcrumb-item active" aria-current="page">CSS Border Radius Generator</li>
          </ol>
        </nav>
      </div>
      {/* <!-- Page Content--> */}
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          {/* <!-- Page Features--> */}
          <div className="row gx-lg-5">
            <p>
              Get perfect rounded corners for your website's elements with our <b>CSS Border Radius Generator</b> at <b>OnlineToolsFor.com</b>. Say goodbye to manual coding and hello to effortless web design. Customize and preview your desired shape with ease. Try it now and elevate your design game!
            </p>
            <div className="container mt-3">
              <section>
                <div className="row">
                  <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <div
                      id="myElement"
                      ref={previewRef}
                      style={{
                        width: "200px",
                        height: "200px",
                        backgroundColor: "#ffffff",
                        border: "2px solid #000",
                        transition: "border-radius 0.3s ease",
                      }}
                    ></div>
                  </div>
                  <div className="col-md-6">
                    <div className="border-radius-options">
                      {['Top-left corner', 'Top-right corner', 'Bottom-right corner', 'Bottom-left corner', 'All corners'].map((cornerLabel, index) => {
                        const cornerKey = index === 4 ? 'radiusAll' : `radius${index + 1}`;
                        return (
                          <div className="row mb-3" key={cornerLabel}>
                            <div className="col-md-4">
                              <label htmlFor={`Range${index}`} className="form-label">
                                {cornerLabel}
                              </label>
                            </div>
                            <div className="col-md-6">
                              <input
                                type="range"
                                className="form-range"
                                min="0"
                                max="100"
                                step="1"
                                id={`Range${index}`}
                                value={radiusValues[cornerKey]}
                                onChange={(e) => updateRadiusValues(cornerKey, e.target.value)}
                              />
                            </div>
                            <div className="col-md-2">
                              <input
                                type="number"
                                className="form-control"
                                id={`Number${index}`}
                                value={radiusValues[cornerKey]}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value === '') return; // Prevent NaN
                                  const numericValue = parseInt(value, 10);
                                  if (numericValue >= 0 && numericValue <= 100) {
                                    updateRadiusValues(cornerKey, numericValue);
                                  }
                                }}
                                min="0"
                                max="100"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12" id="result">
                    <h4>CSS Code:</h4>
                    <pre>
                      <code className="language-css" ref={codeRef}>
                        {cssCode}
                      </code>
                    </pre>
                    <button className="btn btn-primary mt-2 float-end" onClick={handleCopyCode}>
                      Copy Code
                    </button>
                  </div>
                </div>
                <br />
                <br />
              </section>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>
                The "border-radius" property is a tool that web designers use to customize the curvature of an element's corners. It allows them to create buttons, boxes, and images with rounded corners, which can give a softer, more approachable look to a website.
              </p>
              <p>
                This property can accept one to four values, which set the radius of each corner in clockwise order starting from the top-left corner. When using a single value, all four corners have the same radius.
              </p>
              <p><b>For two values:</b></p>
              <ul className='content-listitem'>
                <li>The first value determines the radius of the top-left and bottom-right corners.</li>
                <li>The second value determines the radius of the top-right and bottom-left corners.</li>
              </ul>
              <p><b>For three values:</b></p>
              <ul className='content-listitem'>
                <li>The first value sets the radius of the top-left corner.</li>
                <li>The second value sets the radius of the top-right and bottom-left corners.</li>
                <li>The third value sets the radius of the bottom-right corner.</li>
              </ul>
              <p>
                Finally, using four values sets the radius of each corner individually.
              </p>
              <p>
                Overall, the "border-radius" property is a useful tool in web design that allows for the creation of unique shapes with rounded corners. By using this property, designers can add an extra layer of visual interest and appeal to their websites.
              </p>
              <h2>CSS Border Radius Generator: Create Stunningly Rounded Corners with Ease</h2>
              <p>
                Designers and developers frequently strive to enhance the aesthetic appeal and distinctiveness of their web designs. Rounded corners are a widely popular technique for accomplishing this, as they can provide a softer and more inviting appearance for various elements on a webpage, including buttons and images.
              </p>
              <p>
                However, not everyone may possess sufficient expertise in coding or may have limited time to devote to tweaking values for achieving the desired rounded corners. This is where a CSS Border Radius Generator can prove useful. This tool simplifies the process by enabling designers to modify the curvature visually and generate the essential CSS code for applying the effect on their website without the need for manual coding.
              </p>
              <h2>What is a CSS Border Radius Generator?</h2>
              <p>
                A CSS Border Radius Generator is an online tool that allows you to visually create rounded corners for any element on your webpage. By simply clicking a few times, you have the ability to modify the dimensions, form, and design of your corners without the need for any manual coding.
              </p>
              <h2>Why Use a CSS Border Radius Generator?</h2>
              <p>
                Using a CSS Border Radius Generator can save you time and frustration. Instead of guessing values and testing them out on your website, you can simply use the generator to create your desired look. Moreover, this functionality empowers you to explore various styles and shapes effortlessly, enabling you to discover the ideal aesthetic for your design without any coding required.
              </p>
              <h2>How to Use CSS Border Radius Generator</h2>
              <h3>Using a CSS Border Radius Generator is simple. Here are the basic steps:</h3>
              <ul className='content-listitem'>
                <li>Navigate to the CSS Border Radius Generator.</li>
                <li>Use the sliders or input fields to adjust the size, shape, and style of your corners.</li>
                <li>Preview your changes in real-time.</li>
                <li>Copy the code that was generated and seamlessly integrate it into your stylesheet by pasting it into the appropriate location.</li>
              </ul>
              <h2>Tips for Using a CSS Border Radius Generator</h2>
              <h3>Tips for Using a CSS Border Radius Generator</h3>
              <ul className='content-listitem'>
                <li>Try different values and styles to find the best look for the design.</li>
                <li>Use the same size and shape for rounded corners on all elements to keep a consistent appearance.</li>
                <li>Don't use too many rounded corners, as it may make your design overwhelming.</li>
              </ul>
              <h3>Conclusion</h3>
              <p>
                A CSS Border Radius Generator is a useful resource that simplifies the process of adding rounded corners to a website. It provides a user-friendly visual interface that allows designers and developers to easily create appealing rounded corners that enhance the overall design.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BorderRadiusControl;
