import React, { useState } from "react";
import JSZip from "jszip";
import "./ImageToWebPConverter.css";
import SEO from "../../../SEO";
import { Link } from "react-router-dom";

const ImageToWebPConverter = () => {
  const [downloadLink, setDownloadLink] = useState(null);
  const [downloadLinkVisible, setDownloadLinkVisible] = useState(false);

  const convertToWebP = () => {
    const fileInput = document.getElementById("fileInput");
    const previewContainer = document.getElementById("previewContainer");
    previewContainer.innerHTML = "";
    setDownloadLinkVisible(false);

    const zip = new JSZip();
    const images = [];

    for (let i = 0; i < fileInput.files.length; i++) {
      const file = fileInput.files[i];

      const reader = new FileReader();

      reader.onloadend = () => {
        const thumbnail = document.createElement("div");
        thumbnail.classList.add("thumbnail");
        previewContainer.appendChild(thumbnail);

        const previewImage = document.createElement("img");
        previewImage.src = reader.result;
        previewImage.alt = file.name;
        thumbnail.appendChild(previewImage);

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const img = new Image();

        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          context.drawImage(img, 0, 0);

          const webpData = canvas.toDataURL("image/webp");
          const convertedImage = {
            name: file.name.replace(/\.[^/.]+$/, ".webp"),
            data: webpData,
          };
          images.push(convertedImage);

          zip.file(convertedImage.name, convertedImage.data.split(",")[1], {
            base64: true,
          });

          if (images.length === fileInput.files.length) {
            zip.generateAsync({ type: "blob" }).then((content) => {
              setDownloadLink(URL.createObjectURL(content));
              setDownloadLinkVisible(true);
            });
          }
        };

        img.src = reader.result;
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <SEO
        title="Image to WebP Converter - Convert Images to WebP Format Easily | onlinetoolsfor.com"
        description="Image to WebP Converter - Discover the fastest and simplest way to convert your images to the WebP format using OnlineToolsFor.com. Make your website faster and more efficient today!"
      />
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">Image to WebP Converter</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/web-tools">Web Tools</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Image to WebP Converter
            </li>
          </ol>
        </nav>
      </div>
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          <div className="row gx-lg-5">
            <p>
              <b>Image to WebP Converter</b> - Are you tired of slow-loading
              images on your website? ...
            </p>
            {/* ... Other content ... */}
            <div className="mb-3">
              <form className="form-global">
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  multiple
                  className="form-control"
                />
              </form>
            </div>
            <div className="mb-3">
              <button onClick={convertToWebP} className="btn btn-primary float-end">
                Convert to WebP
              </button>
              {downloadLinkVisible && (
                <a
                  href={downloadLink}
                  download="converted_images.zip"
                  className="btn btn-success"
                >
                  Download All as ZIP
                </a>
              )}
            </div>
            <br />
            <h2 className="mb-4">Preview will show Here</h2>
            <div id="previewContainer"></div>
            <p>
              WebP stands out as a contemporary image format innovated by
              Google, harmonizing the finest attributes: delivering top-notch
              visuals while maintaining compact file sizes.
            </p>
            <h3>Benefits of WebP Format</h3>
            <p>
              <b>1. Faster Load Times:</b> With smaller image sizes, your web
              pages will load quicker, reducing bounce rates and keeping
              visitors engaged.
            </p>
            <p>
              <b>2. Improved SEO:</b> Search engines love fast-loading websites,
              and using WebP can give your site an edge in search rankings.
            </p>
            <p>
              <b>3. Bandwidth Savings:</b> WebP's efficient compression can save
              you money on hosting costs by reducing data usage.
            </p>
            <p>
              Now that you understand the advantages of WebP, let's explore how
              OnlineToolsFor.com can simplify the image conversion process for
              you.
            </p>
            <h2>Your Go-To Image Conversion Tool</h2>
            <p>
              At OnlineToolsFor.com, we believe in making complex tasks simple.
              Our Image to WebP Converter does exactly that – it transforms your
              images into the WebP format effortlessly. You don't need to be
              tech-savvy; our user-friendly interface guides you through the
              process step by step.
            </p>
            <h3>How to Convert Images to WebP</h3>
            <p>
              <b>1. Upload Your Image:</b> Click on the "Choose File" and select
              the image you want to convert.
            </p>
            <p>
              <b>3. Click Convert:</b> Our tool works its magic and presents you
              with a downloadable WebP image in seconds.
            </p>
            <h2>Why Choose OnlineToolsFor.com?</h2>
            <h3>Unparalleled Simplicity and Convenience</h3>
            <p>
              Acknowledging that technical expertise isn't universal, we've
              crafted our tool to prioritize maximum intuitiveness. Converting
              images shouldn't be a headache – and with us, it isn't!
            </p>
            <h3>The OnlineToolsFor.com Advantage</h3>
            <p>
              <b>1. No Registration Needed:</b> You don't have to create an
              account – simply visit our website and start converting.
            </p>
            <p>
              <b>2. Wide Compatibility:</b> Our tool works seamlessly on all
              devices, be it a computer, tablet, or smartphone.
            </p>
            <p>
              <b>3. Secure and Private:</b> We prioritize your data security.
              Your uploaded images are deleted automatically after conversion.
            </p>
            <p>
              With OnlineToolsFor.com, converting images to WebP is no longer a
              daunting task. But wait, there's more – let's talk about
              optimization.
            </p>
            <h2>Elevate Your Website's Performance</h2>
            <p>
              Enhancing the overall performance of your website involves a
              pivotal stride - the optimization of its images. By switching to
              the WebP format, you've already taken a significant stride. Now,
              let's explore some additional optimization tips.
            </p>
            <h3>Resize Images Appropriately</h3>
            <p>
              Large images can still slow down your site, even in WebP format.
              Use our recommended image dimensions for various page elements to
              ensure the best fit and loading speed.
            </p>
            <h3>Leverage Lazy Loading</h3>
            <p>
              Lazy loading ensures that images only load when they come into the
              user's view, reducing initial page load time.
            </p>
            <h3>Compress Other Content</h3>
            <p>
              Apart from images, consider compressing your website's CSS and
              JavaScript files to further boost loading times.
            </p>
            <h3>Optimize Your Images Today</h3>
            <p>
              Don't let sluggish images drag down your website's performance.
              Embrace the future with WebP and make use of OnlineToolsFor.com's
              easy-to-use Image to WebP Converter. Try our converter now – it's
              time to take your web presence to the next level!
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageToWebPConverter;
