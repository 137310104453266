import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

function Navigation() {
  return (
    <>
      <div itemscope itemtype="http://schema.org/Organization">
        <meta itemProp="url" content="https://onlinetoolsfor.com/" />
        <meta itemProp="logo" content="https://onlinetoolsfor.com/img/logo.webp" />
      </div>
      <Navbar bg="dark" expand="lg" variant="dark" className="custom-navbar">
        <div className="container px-lg-5">
          <Navbar.Brand as={Link} to="/">
            <img
              src="/assets/images/logo.webp"
              alt="onlinetoolsfor"
              width="244"  // Set the actual width of your logo image
              height="40"  // Set the actual height of your logo image
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/" className="nav-link">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/About" className="nav-link">
                About Us
              </Nav.Link>
              
              {/* Web Tools Dropdown */}
              <NavDropdown
                title={<span>Web Tools</span>}
                id="web-tools-dropdown"
                className="nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/web-tools">
                  Web Tools
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/web-tools/word-character-counter">
                  Word and Character Counter
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/web-tools/case-changer-tool">
                  Case Changer Tool
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/web-tools/css-minifier-tool">
                  CSS Minifier Tool
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/web-tools/css-beautifier-tool">
                  CSS Beautifier Tool
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/web-tools/php-array-to-excel">
                  PHP Array to Excel Converter
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/web-tools/image-to-webp-converter">
                  Image to WebP Converter
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/web-tools/invoice-generator">
                  Invoice Generator
                </NavDropdown.Item>
              </NavDropdown>
              
              {/* CSS Generators Dropdown */}
              <NavDropdown
                title={<span>CSS Generators</span>}
                id="css-generators-dropdown"
                className="nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/css-generators">
                  CSS Generators
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/css-generators/css-gradient-generator">
                  CSS Gradient Generator
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/css-generators/css-boxshadow-generator">
                  CSS Box Shadow Generator
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/css-generators/css-border-radius-generator">
                  CSS Border Radius Generator
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/css-generators/css-button-generator">
                  CSS Button Generator
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/css-generators/css-triangle-generator">
                  CSS Triangle Generator
                </NavDropdown.Item>
              </NavDropdown>
              
              {/* SEO Tools Dropdown */}
              <NavDropdown
                title={<span>SEO Tools</span>}
                id="seo-tools-dropdown"
                className="nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/seo-tools">
                  SEO Tools
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/seo-tools/text-to-slug">
                  Text to Slug
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/seo-tools/title-description-tool">
                  Title & Description Tool
                </NavDropdown.Item>
              </NavDropdown>
              
              {/* Online Calculators Dropdown */}
              <NavDropdown
                title={<span>Online Calculators</span>}
                id="online-calculators-dropdown"
                className="nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/online-calculators">
                  Online Calculators
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/online-calculators/paypal-fee-calculator">
                  Paypal Fee Calculator
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/online-calculators/loan-calculator">
                  Loan Calculator
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/online-calculators/percentage-calculator">
                  Percentage Calculator
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/online-calculators/age-calculator">
                  Age Calculator
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/online-calculators/mutual-fund-calculator">
                  Mutual Fund Calculator
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/online-calculators/bmi-calculator">
                  BMI Calculator
                </NavDropdown.Item>
              </NavDropdown>
              
              <Nav.Link as={Link} to="/contact" className="nav-link">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}

export default Navigation;
