// InvoiceDetails.js
import React from "react";

const InvoiceDetails = ({ invoiceData, setInvoiceData }) => {
  return (
    <div className="section form-global">
      <label htmlFor="invoice" class="form-label"><b>Invoice Details:</b></label>
      <input
        className="form-control"
        id="invoice"
        type="text"
        placeholder="Invoice Number"
        value={invoiceData.invoice.number}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            invoice: { ...invoiceData.invoice, number: e.target.value },
          })
        }
      />
      <label htmlFor="invoiceDate" class="form-label"><b>Invoice Date:</b></label>
      <input
        className="form-control"
        id="invoiceDate"
        type="date"
        placeholder="Invoice Date"
        value={invoiceData.invoice.date}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            invoice: { ...invoiceData.invoice, date: e.target.value },
          })
        }
      />

      <label htmlFor="dueDate" class="form-label"><b>Due Date:</b></label>
      <input
        className="form-control"
        id="dueDate"
        type="date"
        placeholder="Due Date"
        value={invoiceData.invoice.dueDate}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            invoice: { ...invoiceData.invoice, dueDate: e.target.value },
          })
        }
      />

    </div>
  );
};

export default InvoiceDetails;
