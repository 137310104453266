// ButtonStyler.jsx
import React, { useState, useEffect, useRef } from 'react';
import './button-generator.css';
import SEO from "../../../SEO";
import { Link } from 'react-router-dom';
import Prism from "prismjs";
import "prismjs/themes/prism.css"; // Import Prism CSS theme
import "prismjs/components/prism-css"; // Import CSS syntax highlighting

const ButtonStyler = () => {
  // Refs for the button preview and code block
  const buttonPreviewRef = useRef(null);
  const codeRef = useRef(null);

  // State for Button Styles
  const [buttonType, setButtonType] = useState('solid');
  const [solidColors, setSolidColors] = useState({
    bgColor: '#1164E8',
    hoverColor: '#3A7FEE',
  });
  const [gradientColors, setGradientColors] = useState({
    top: '#1164E8',
    bottom: '#3A7FEE',
    topHover: '#009900',
    bottomHover: '#005500',
  });
  const [textColors, setTextColors] = useState({
    color: '#FFFFFF',
    hoverColor: '#FFFFFF',
  });
  const [borderOptions, setBorderOptions] = useState({
    style: 'none',
    weight: 1,
    color: '#000000',
    radius: 18,
  });
  const [padding, setPadding] = useState({ top: 12, right: 15, bottom: 12, left: 15 });
  const [textShadow, setTextShadow] = useState({
    x: 0,
    y: 0,
    blur: 0,
    color: '#000000',
  });
  const [boxShadow, setBoxShadow] = useState({
    x: 1,
    y: 1,
    blur: 9,
    color: '#000000',
  });
  const [textStyle, setTextStyle] = useState({
    align: 'left',
    fontFamily: 'Arial',
    fontSize: 16,
    lineHeight: 1.5,
  });

  const [generatedCSS, setGeneratedCSS] = useState('');

  // State to manage hover effects
  const [isHovered, setIsHovered] = useState(false);

  // Update the button preview styles and generate CSS
  useEffect(() => {
    const buttonPreview = buttonPreviewRef.current;
    if (!buttonPreview) return;

    // Apply common styles
    buttonPreview.style.color = textColors.color;
    buttonPreview.style.textAlign = textStyle.align;
    buttonPreview.style.fontFamily = textStyle.fontFamily;
    buttonPreview.style.fontSize = `${textStyle.fontSize}px`;
    buttonPreview.style.lineHeight = textStyle.lineHeight;
    buttonPreview.style.borderStyle = borderOptions.style;
    buttonPreview.style.borderWidth = `${borderOptions.weight}px`;
    buttonPreview.style.borderColor = borderOptions.color;
    buttonPreview.style.borderRadius = `${borderOptions.radius}px`;
    buttonPreview.style.paddingTop = `${padding.top}px`;
    buttonPreview.style.paddingRight = `${padding.right}px`;
    buttonPreview.style.paddingBottom = `${padding.bottom}px`;
    buttonPreview.style.paddingLeft = `${padding.left}px`;
    buttonPreview.style.textShadow = `${textShadow.x}px ${textShadow.y}px ${textShadow.blur}px ${textShadow.color}`;
    buttonPreview.style.boxShadow = `${boxShadow.x}px ${boxShadow.y}px ${boxShadow.blur}px ${boxShadow.color}`;
    buttonPreview.style.transition = 'background-color 0.3s, color 0.3s';

    // Apply background based on button type and hover state
    if (buttonType === 'solid') {
      buttonPreview.style.backgroundColor = isHovered ? solidColors.hoverColor : solidColors.bgColor;
      buttonPreview.style.backgroundImage = 'none';
    } else {
      const gradientValue = `linear-gradient(to bottom, ${gradientColors.top}, ${gradientColors.bottom})`;
      const hoverGradientValue = `linear-gradient(to bottom, ${gradientColors.topHover}, ${gradientColors.bottomHover})`;
      buttonPreview.style.background = isHovered ? hoverGradientValue : gradientValue;
    }

    // Update text color based on hover state
    buttonPreview.style.color = isHovered ? textColors.hoverColor : textColors.color;

    // Generate CSS code
    const cssCode = `
.button-preview {
  background: ${buttonType === 'solid' ? solidColors.bgColor : `linear-gradient(to bottom, ${gradientColors.top}, ${gradientColors.bottom})`};
  color: ${textColors.color};
  text-align: ${textStyle.align};
  font-family: ${textStyle.fontFamily};
  font-size: ${textStyle.fontSize}px;
  line-height: ${textStyle.lineHeight};
  border-style: ${borderOptions.style};
  border-width: ${borderOptions.weight}px;
  border-color: ${borderOptions.color};
  border-radius: ${borderOptions.radius}px;
  padding: ${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px;
  text-shadow: ${textShadow.x}px ${textShadow.y}px ${textShadow.blur}px ${textShadow.color};
  box-shadow: ${boxShadow.x}px ${boxShadow.y}px ${boxShadow.blur}px ${boxShadow.color};
  transition: background-color 0.3s, color 0.3s;
}

/* Hover styles */
.button-preview:hover {
  background: ${buttonType === 'solid' ? solidColors.hoverColor : `linear-gradient(to bottom, ${gradientColors.topHover}, ${gradientColors.bottomHover})`};
  color: ${textColors.hoverColor};
}
    `.trim();

    setGeneratedCSS(cssCode);
  }, [
    buttonType,
    solidColors,
    gradientColors,
    textColors,
    borderOptions,
    padding,
    textShadow,
    boxShadow,
    textStyle,
    isHovered,
  ]);

  // Ensure Prism.js highlights the updated code
  useEffect(() => {
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current);
    }
  }, [generatedCSS]);

  // Handle Copy to Clipboard
  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(generatedCSS)
      .then(() => {
        // Optional: Integrate a toast notification for better UX
        alert('CSS code copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
        alert('Failed to copy CSS code. Please try again.');
      });
  };

  // Handle Radio Button Change
  const handleRadioChange = (e) => {
    setButtonType(e.target.value);
  };

  return (
    <>
      <SEO
        title="CSS Button Generator - Free Button Generator Tool | OnlineToolsFor.com"
        description="Easily create custom CSS buttons with our free CSS button generator. Choose from a range of styles and effects to create call-to-action buttons that will enhance your web design projects."
      />
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">CSS Button Generator</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/css-generators">CSS Generators</Link></li>
            <li className="breadcrumb-item active" aria-current="page">CSS Button Generator</li>
          </ol>
        </nav>
      </div>
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          {/* <!-- Page Features--> */}
          <div className="row gx-lg-5">
            <div className="col-md-12">
              <p>
                <b>CSS Button Generator</b> on <b>onlinetoolsfor.com</b> is a user-friendly tool that allows you to effortlessly customize buttons with different colors, sizes, and styles. With real-time preview functionality, you can see your changes come to life before generating the CSS code. And the best part is, you can use it for free! In this article, we'll delve into the CSS Button Generator, exploring its features and how it can help you craft professional-looking buttons without any hassle.
              </p>
              <br />
            </div>
            <div className="col-md-4">
              <button
                id="button-preview"
                ref={buttonPreviewRef}
                className="btn sticky-top preview-button button-preview mb-3"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Preview Button
              </button>
            </div>
            <div className="col-md-8">
              <form id="button-form">
                {/* Button Type Selection */}
                <div className="row mb-3">
                  <div>
                    <label className="form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="button-type"
                        value="solid"
                        checked={buttonType === 'solid'}
                        onChange={handleRadioChange}
                      />
                      &nbsp;Solid
                    </label>
                    <label className="form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="button-type"
                        value="gradient"
                        checked={buttonType === 'gradient'}
                        onChange={handleRadioChange}
                      />
                      &nbsp;Gradient
                    </label>
                  </div>
                </div>

                {/* Solid Button Controls */}
                {buttonType === 'solid' && (
                  <>
                    <div className="row mb-3">
                      <label htmlFor="solid-bg-color" className="col-sm-4 col-form-label">
                        Background Color:
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control form-control-color"
                          type="color"
                          id="solid-bg-color"
                          value={solidColors.bgColor}
                          onChange={(e) =>
                            setSolidColors({ ...solidColors, bgColor: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="solid-hover-color" className="col-sm-4 col-form-label">
                        Background Hover Color:
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control form-control-color"
                          type="color"
                          id="solid-hover-color"
                          value={solidColors.hoverColor}
                          onChange={(e) =>
                            setSolidColors({ ...solidColors, hoverColor: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* Gradient Button Controls */}
                {buttonType === 'gradient' && (
                  <>
                    <div className="row mb-3">
                      <label htmlFor="gradient-top-color" className="col-sm-4 col-form-label">
                        Top Gradient Color:
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control form-control-color"
                          type="color"
                          id="gradient-top-color"
                          value={gradientColors.top}
                          onChange={(e) =>
                            setGradientColors({ ...gradientColors, top: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="gradient-bottom-color" className="col-sm-4 col-form-label">
                        Bottom Gradient Color:
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control form-control-color"
                          type="color"
                          id="gradient-bottom-color"
                          value={gradientColors.bottom}
                          onChange={(e) =>
                            setGradientColors({ ...gradientColors, bottom: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="gradient-top-hover-color" className="col-sm-4 col-form-label">
                        Top Gradient Hover Color:
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control form-control-color"
                          type="color"
                          id="gradient-top-hover-color"
                          value={gradientColors.topHover}
                          onChange={(e) =>
                            setGradientColors({ ...gradientColors, topHover: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="gradient-bottom-hover-color" className="col-sm-4 col-form-label">
                        Bottom Gradient Hover Color:
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control form-control-color"
                          type="color"
                          id="gradient-bottom-hover-color"
                          value={gradientColors.bottomHover}
                          onChange={(e) =>
                            setGradientColors({ ...gradientColors, bottomHover: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                <hr />

                {/* Text Color Controls */}
                <div className="row mb-3">
                  <label htmlFor="text-color" className="col-sm-4 col-form-label">
                    Text Color:
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control form-control-color"
                      type="color"
                      id="text-color"
                      value={textColors.color}
                      onChange={(e) =>
                        setTextColors({ ...textColors, color: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="text-hover-color" className="col-sm-4 col-form-label">
                    Text Hover Color:
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control form-control-color"
                      type="color"
                      id="text-hover-color"
                      value={textColors.hoverColor}
                      onChange={(e) =>
                        setTextColors({ ...textColors, hoverColor: e.target.value })
                      }
                    />
                  </div>
                </div>

                <hr />

                {/* Border Controls */}
                <div className="row mb-3">
                  <label htmlFor="border-style" className="col-sm-4 col-form-label">
                    Border Style
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="form-select"
                      id="border-style"
                      value={borderOptions.style}
                      onChange={(e) =>
                        setBorderOptions({ ...borderOptions, style: e.target.value })
                      }
                    >
                      <option value="none">None</option>
                      <option value="solid">Solid</option>
                      <option value="dashed">Dashed</option>
                      <option value="dotted">Dotted</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="border-weight" className="col-sm-4 col-form-label">
                    Border Weight
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="number"
                      id="border-weight"
                      value={borderOptions.weight}
                      onChange={(e) =>
                        setBorderOptions({ ...borderOptions, weight: e.target.value })
                      }
                      min="0"
                      max="20"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="border-color" className="col-sm-4 col-form-label">
                    Border Color
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control form-control-color"
                      type="color"
                      id="border-color"
                      value={borderOptions.color}
                      onChange={(e) =>
                        setBorderOptions({ ...borderOptions, color: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="border-radius" className="col-sm-4 col-form-label">
                    Border Radius
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-range"
                      type="range"
                      id="border-radius"
                      min="0"
                      max="50"
                      value={borderOptions.radius}
                      onChange={(e) =>
                        setBorderOptions({ ...borderOptions, radius: e.target.value })
                      }
                    />
                    <span>{borderOptions.radius}px</span>
                  </div>
                </div>

                <hr />

                {/* Padding Controls */}
                <div className="row mb-3">
                  <label className="col-sm-4 col-form-label">Add Padding</label>
                  <div className="col-sm-8">
                    <div className="row mb-3">
                      <label htmlFor="padding-top" className="col-sm-4 col-form-label">
                        Top
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type="number"
                          id="padding-top"
                          value={padding.top}
                          onChange={(e) =>
                            setPadding({ ...padding, top: e.target.value })
                          }
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="padding-right" className="col-sm-4 col-form-label">
                        Right
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type="number"
                          id="padding-right"
                          value={padding.right}
                          onChange={(e) =>
                            setPadding({ ...padding, right: e.target.value })
                          }
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="padding-bottom" className="col-sm-4 col-form-label">
                        Bottom
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type="number"
                          id="padding-bottom"
                          value={padding.bottom}
                          onChange={(e) =>
                            setPadding({ ...padding, bottom: e.target.value })
                          }
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="padding-left" className="col-sm-4 col-form-label">
                        Left
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type="number"
                          id="padding-left"
                          value={padding.left}
                          onChange={(e) =>
                            setPadding({ ...padding, left: e.target.value })
                          }
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                {/* Text Shadow Controls */}
                <div className="row mb-3">
                  <label className="col-sm-4 col-form-label">Text Shadow</label>
                  <div className="col-sm-8">
                    <div className="row mb-3">
                      <label htmlFor="text-shadow-x" className="col-sm-4 col-form-label">
                        X
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type="number"
                          id="text-shadow-x"
                          value={textShadow.x}
                          onChange={(e) =>
                            setTextShadow({ ...textShadow, x: e.target.value })
                          }
                          min="-50"
                          max="50"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="text-shadow-y" className="col-sm-4 col-form-label">
                        Y
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type="number"
                          id="text-shadow-y"
                          value={textShadow.y}
                          onChange={(e) =>
                            setTextShadow({ ...textShadow, y: e.target.value })
                          }
                          min="-50"
                          max="50"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="text-shadow-blur" className="col-sm-4 col-form-label">
                        Blur
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type="number"
                          id="text-shadow-blur"
                          value={textShadow.blur}
                          onChange={(e) =>
                            setTextShadow({ ...textShadow, blur: e.target.value })
                          }
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="text-shadow-color" className="col-sm-4 col-form-label">
                        Color
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control form-control-color"
                          type="color"
                          id="text-shadow-color"
                          value={textShadow.color}
                          onChange={(e) =>
                            setTextShadow({ ...textShadow, color: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                {/* Box Shadow Controls */}
                <div className="row mb-3">
                  <label className="col-sm-4 col-form-label">Box Shadow</label>
                  <div className="col-sm-8">
                    <div className="row mb-3">
                      <label htmlFor="box-shadow-x" className="col-sm-4 col-form-label">
                        X
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type="number"
                          id="box-shadow-x"
                          value={boxShadow.x}
                          onChange={(e) =>
                            setBoxShadow({ ...boxShadow, x: e.target.value })
                          }
                          min="-50"
                          max="50"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="box-shadow-y" className="col-sm-4 col-form-label">
                        Y
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type="number"
                          id="box-shadow-y"
                          value={boxShadow.y}
                          onChange={(e) =>
                            setBoxShadow({ ...boxShadow, y: e.target.value })
                          }
                          min="-50"
                          max="50"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="box-shadow-blur" className="col-sm-4 col-form-label">
                        Blur
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          type="number"
                          id="box-shadow-blur"
                          value={boxShadow.blur}
                          onChange={(e) =>
                            setBoxShadow({ ...boxShadow, blur: e.target.value })
                          }
                          min="0"
                          max="100"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="box-shadow-color" className="col-sm-4 col-form-label">
                        Color
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control form-control-color"
                          type="color"
                          id="box-shadow-color"
                          value={boxShadow.color}
                          onChange={(e) =>
                            setBoxShadow({ ...boxShadow, color: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                {/* Text Style Controls */}
                <div className="row mb-3">
                  <label htmlFor="text-align" className="col-sm-4 col-form-label">
                    Text Alignment
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="form-select"
                      id="text-align"
                      value={textStyle.align}
                      onChange={(e) =>
                        setTextStyle({ ...textStyle, align: e.target.value })
                      }
                    >
                      <option value="left">Left</option>
                      <option value="center">Center</option>
                      <option value="right">Right</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="font-family" className="col-sm-4 col-form-label">
                    Font Family
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="form-select"
                      id="font-family"
                      value={textStyle.fontFamily}
                      onChange={(e) =>
                        setTextStyle({ ...textStyle, fontFamily: e.target.value })
                      }
                    >
                      <option value="Arial">Arial</option>
                      <option value="Courier New">Courier New</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Comic Sans MS">Comic Sans MS</option>
                      <option value="Lucida Sans Unicode">Lucida Sans Unicode</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="font-size" className="col-sm-4 col-form-label">
                    Font Size
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="number"
                      id="font-size"
                      value={textStyle.fontSize}
                      onChange={(e) =>
                        setTextStyle({ ...textStyle, fontSize: e.target.value })
                      }
                      min="8"
                      max="72"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="line-height" className="col-sm-4 col-form-label">
                    Line Height
                  </label>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="number"
                      step="0.1"
                      id="line-height"
                      value={textStyle.lineHeight}
                      onChange={(e) =>
                        setTextStyle({ ...textStyle, lineHeight: e.target.value })
                      }
                      min="1"
                      max="3"
                    />
                  </div>
                </div>
              </form>
            </div>

            {/* CSS Code Display */}
            <div className="row mt-4">
              <div className="col-md-12" id="result">
                <h4>CSS Code:</h4>
                <pre>
                  <code className="language-css" ref={codeRef}>
                    {generatedCSS}
                  </code>
                </pre>
                <button className="btn btn-primary mt-2 float-end" onClick={handleCopyButtonClick}>
                  Copy CSS Code
                </button>
              </div>
            </div>

            <br /> <br />

            {/* Informational Content */}
            <div className="row">
              <div className="col-md-12">
                <h2>What is CSS Button Generator?</h2>
                <p>
                  CSS Button Generator is a convenient online tool designed for creating customized buttons for websites. It offers a simple interface that eliminates the need for manual coding. This tool is particularly beneficial for individuals lacking design skills or those who prefer not to spend excessive time designing buttons from scratch. Let's delve into CSS Button Generator and discover how it can empower you to create visually appealing buttons that seamlessly integrate with your website's style.
                </p>

                <h3>Features of CSS Button Generator</h3>
                <p>
                  CSS Button Generator boasts an array of impressive features to assist you in fashioning professional-looking buttons for your website. Let's explore some of its notable features:
                </p>
                <ul className='content-listitem'>
                  <li>
                    <b>Button Styles:</b> CSS Button Generator offers a diverse range of styles, including flat, gradient, and 3D, enabling you to choose the perfect style to complement your website's design.
                  </li>
                  <li>
                    <b>Button Colors:</b> Customize the color of your buttons to harmonize with your website's color scheme. CSS Button Generator provides a color picker tool with an extensive range of colors to select from.
                  </li>
                  <li>
                    <b>Button Effects:</b> Elevate the interactivity and engagement of your buttons with CSS Button Generator's collection of effects. Experiment with hover effects and click effects to enhance user experience.
                  </li>
                  <li>
                    <b>Button Code:</b> Once you've finalized your button design, CSS Button Generator generates the necessary code. Just copy and paste the code into your website's HTML file, and you're all set! Your buttons are ready to go.
                  </li>
                </ul>
                <h2>Why Use CSS Button Generator?</h2>
                <p>
                  CSS Button Generator offers numerous advantages for web developers seeking professional-looking buttons for their websites. Consider the following benefits:
                </p>
                <ul className='content-listitem'>
                  <li>
                    <b>Easy to Use:</b> CSS Button Generator features a user-friendly interface that eliminates the need for coding knowledge. Design and customize buttons effortlessly, saving valuable time and effort.
                  </li>
                  <li>
                    <b>Time-Saver:</b> With CSS Button Generator, you can create customized buttons within minutes. Say goodbye to time-consuming manual designs and welcome the efficiency of this intuitive tool.
                  </li>
                  <li>
                    <b>Consistent Design:</b> Maintain a consistent design aesthetic throughout your website with buttons that seamlessly blend with your chosen style. CSS Button Generator ensures your buttons harmonize with your website's overall look and feel.
                  </li>
                  <li>
                    <b>Cross-Browser Compatibility:</b> Generate code that is compatible with all major web browsers, guaranteeing a consistent button appearance across various devices and platforms.
                  </li>
                </ul>
                <h2>Conclusion</h2>
                <p>
                  In conclusion, CSS Button Generator is an invaluable tool for web developers aiming to create professional-looking buttons for their websites. With its easy-to-use interface, time-saving features, consistent design options, and compatibility across different web browsers, CSS Button Generator becomes an essential tool to have. By utilizing CSS Button Generator, you can enhance your website's visual appeal, improve user experience, and even boost your search engine ranking. Take advantage of this SEO-friendly tool today and watch your website's buttons come to life with style and functionality.
                </p>
              </div>
            </div>
          </div>
          </div>
        </section>
      </>
    );
  };

  export default ButtonStyler;
