// PaymentInfo.js
import React from "react";

const PaymentInfo = ({ invoiceData, setInvoiceData }) => {
  // const handleFileChange = (e, field) => {
  //   if (e.target.files && e.target.files[0]) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const base64String = reader.result;
  //       setInvoiceData((prevData) => ({
  //         ...prevData,
  //         paymentInfo: { ...prevData.paymentInfo, [field]: base64String },
  //       }));
  //     };
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // };

  return (
    <div className="section form-global">
      <label htmlFor="paymentinfo" class="form-label"><b>Payment Information:</b></label>
      <input
        className="form-control"
        id="paymentinfo"
        type="text"
        placeholder="Accepted Payment Methods"
        value={invoiceData.paymentInfo.methods}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            paymentInfo: { ...invoiceData.paymentInfo, methods: e.target.value },
          })
        }
      />
      <input
        className="form-control"
        type="text"
        placeholder="Bank Details (if applicable)"
        value={invoiceData.paymentInfo.bankDetails}
        onChange={(e) =>
          setInvoiceData({
            ...invoiceData,
            paymentInfo: { ...invoiceData.paymentInfo, bankDetails: e.target.value },
          })
        }
      />
      {/* <input
        type="file"
        accept="image/png, image/jpeg"
        onChange={(e) => handleFileChange(e, "qrCode")}
      /> */}
    </div>
  );
};

export default PaymentInfo;
