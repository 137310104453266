import React, { useState } from "react";
import "./css-minifier.css";
import SEO from "../../../SEO";
import { Link } from "react-router-dom";

const CssMinifierTool = () => {
  const [inputCss, setInputCss] = useState("");
  const [minifiedCss, setMinifiedCss] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const minify = () => {
    if (!inputCss.trim()) {
      setErrorMessage("Please enter some CSS code.");
      return;
    }

    setErrorMessage(""); // Clear previous error messages
    setIsLoading(true); // Start loading state

    // Minify the CSS code
    const minified = inputCss
      .replace(/\/\*[\s\S]*?\*\/|([^:]|^)\/\/.*$/gm, "")
      .replace(/\s+/g, " ")
      .replace(/(\s+)?([{}|:;,>~+[\]])+(\s+)?/g, "$2");

    setMinifiedCss(minified);
    setIsLoading(false); // End loading state
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(minifiedCss);
    alert("Minified CSS copied to clipboard!");
  };

  return (
    <>
      <SEO
        title="CSS Minifier Tool | Optimize Website Performance with onlinetoolsfor.com"
        description="Optimize your website\'s performance with onlinetoolsfor.com\'s easy-to-use CSS minifier tool. Compress your CSS files for faster page load speeds and a better user experience. Try it now!"
      />
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">CSS Minifier Tool</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/web-tools">Web Tools</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              CSS Minifier Tool
            </li>
          </ol>
        </nav>
      </div>
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          <div className="row gx-lg-5">
            <p className="mb-4">
              A <b>CSS minifier tool</b> is a software that eliminates
              unnecessary characters and spaces from a CSS file to decrease its
              size...
            </p>
            <div className="col-md-12">
              <form className="form-global">
                <div className="mb-3">
                  <label htmlFor="input" className="form-label">
                    <h3>Input CSS:</h3>
                  </label>
                  <textarea
                    id="input"
                    className="form-control"
                    rows="10"
                    value={inputCss}
                    onChange={(e) => setInputCss(e.target.value)}
                  />
                </div>
                {errorMessage && (
                  <div className="alert alert-danger">{errorMessage}</div>
                )}
                <div className="mb-3">
                  <button
                    type="button"
                    className="btn btn-primary float-end"
                    onClick={minify}
                    disabled={isLoading}
                  >
                    {isLoading ? "Minifying..." : "Minify"}
                  </button>
                  <br />
                  <br />
                </div>
              </form>
              <div className="mb-3">
                <h3>Minified CSS:</h3>
                <textarea
                  id="output"
                  className="form-control"
                  rows="10"
                  value={minifiedCss}
                  readOnly
                />
                <button
                  className="btn btn-secondary mt-2 float-end"
                  onClick={copyToClipboard}
                  disabled={!minifiedCss}
                >
                  Copy to Clipboard
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div class="col-md-12">
              <p>
                The well-known CSS Minifier Tool is used to minimise the size of
                CSS files by removing extraneous characters such void spaces,
                comments, and line breaks. A crucial tool for web developers
                need to optimize the performance of their webpage by diminishing
                the time it takes for pages to load. We'll go through the
                advantages of using a CSS Minifier Tool in this article, along
                with how it functions.
              </p>
              <h2>Advantages of Utilizing CSS Minifier Tool:</h2>
              <ul className='content-listitem'>
                <li>
                  Further developed Page Speed: By eliminating unnecessary
                  characters from the CSS file, the tool assists with reduce the
                  size of the file, which prompts quicker page load times. This
                  improved speed results in a better user experience and
                  improved SEO rankings.
                </li>
                <li>
                  Reduced Bandwidth: By reducing the size of the CSS file, the
                  website's bandwidth usage is decreased, which saves website
                  owners money.
                </li>
                <li>
                  Cleaner Code: Minifying CSS code makes it easier to read and
                  maintain. The intelligibility of the file is improved by
                  eliminating unnecessary code, which simplifies it for
                  developers to appreciate the design and make alterations.
                </li>
                <li>
                  Compatibility: CSS Minifier Tool is viable with all cutting
                  edge browsers and operating systems, guaranteeing that the
                  tool can be utilized on any website with no compatibility
                  issues.
                </li>
              </ul>
              <h2>How Does CSS Minifier Tool Work?</h2>
              <p>
                CSS Minifier Tool works by analyzing the CSS file and removing
                all unnecessary characters, such as white spaces, comments, and
                line breaks. This process reduces the size of the file without
                changing its functionality.
              </p>
              <p>
                The tool uses advanced algorithms to analyze the CSS code and
                remove any unnecessary characters. The outcome is a lesser,
                better performing file that loads faster, thereby improving the
                efficiency of the website.
              </p>
              <h2>CSS Minifier Tool Use:</h2>
              <h3>
                You must carry out these few actions in order to use the tool:
              </h3>
              <ul className='content-listitem'>
                <li>In the tool, copy and paste the CSS code.</li>
                <li>Click on the "Minify" button.</li>
                <li>
                  The tool will analyze the code and remove any unnecessary
                  characters.
                </li>
                <li>
                  Replace your original script with the optimised version by
                  copying the original code.
                </li>
              </ul>
              <h3>Conclusion:</h3>
              <p>
                Web designers that want to boost the utility of their website
                through speeding up its loading time need to use the CSS
                Minifier Tool. The tool is simple to use and provides plenty of
                benefits namely compatibility, bandwidth savings, faster pages,
                and cleaner code. Web designers may make ensure their website is
                effective, cost-effective, and simple to use through the use of
                the CSS Minifier Tool.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CssMinifierTool;
