import React from 'react'
import { Container, Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SEO from '../SEO';

function About() {

  return (
    <>
        <SEO
            title="Online Tools & Generators | Web Design, Development & SEO - OnlineToolsFor.com"
            description="OnlineToolsFor.com provides a comprehensive collection of online tools, CSS generators, text editors, and online converters for web designers, developers, and SEO professionals. Our resources make it easy to create and customize your website, optimize your content for search engines, and analyze your website\'s performance. Improve your online presence today with our powerful and user-friendly tools."
        />
        
        {/* <!-- Header--> */}
          <header className="pt-5">
              <div className="container px-lg-5">
                  <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                      <div className="m-4 m-lg-5">
                          <h1 className="display-5 fw-bold">About Us</h1>
                      </div>
                  </div>
              </div>
          </header>
        <Container className="px-lg-5">
            <Breadcrumb className="px-3 py-2">
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item active aria-current="page">About Us</Breadcrumb.Item>
            </Breadcrumb>
        </Container>
      {/* <!-- Page Content--> */}
      <section className="pt-4 pb-5">
          <div className="container px-lg-5">
              {/* <!-- Page Features--> */}
              <div className="row gx-lg-5">
                  <p>
                      Welcome to Onlinetoolsfor - your ultimate resource for web designers and developers. We specialize in providing a scope of online tools intended to assist you with smoothing out your work processes and lift efficiency.
                  </p>

                  <p>
                      Our products include CSS generators, CSS tools, HTML tools, CSS compilers, optimizers, table and grid generators, and much more. Whether you're a seasoned pro or simply getting started with website design and development, our tools are intended to make your life simpler and assist you with accomplishing more significantly quicker.
                  </p>

                  <p>
                      At Onlinetoolsfor, we believe that technology should be accessible to everyone. That is the reason our tools are not difficult to use, instinctive, and require no programming abilities. Just select the tools you want, customize it to your particulars, and allow it to accomplish the difficult work for you.
                  </p>

                  <p>
                      We are relentless in our commitment to delivering exceptional products, and our team of seasoned experts persistently work towards testing and refining our tools, securing their unmatched reputation in the industry. We additionally give ordinary updates and support to guarantee that you generally approach the most recent elements and usefulness.
                  </p>

                  <p>
                      Thank you for choosing Onlinetoolsfor. We anticipate assisting you with accomplishing your objectives and open your maximum capacity.
                  </p>
              </div>
          </div>
      </section>
    </>
  )
}

export default About