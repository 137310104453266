import React, { useState, useMemo, useCallback } from "react";
import "./case-changer.css";
import SEO from "../../../SEO";
import { Link } from "react-router-dom";

// Utility function to capitalize the first letter
const capitalizeFirstLetter = (word) => {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

// Function to convert text to sentence case
const toSentenceCase = (text) => {
  return text
    .split(". ")
    .map((sentence) => {
      const words = sentence.split(" ");
      return words
        .map((word, idx) =>
          idx === 0 ? capitalizeFirstLetter(word) : word.toLowerCase()
        )
        .join(" ");
    })
    .join(". ");
};

// Function to convert text to title case
const toTitleCase = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map(capitalizeFirstLetter)
    .join(" ");
};

const CaseChanger = () => {
  const [inputText, setInputText] = useState("");
  const [caseType, setCaseType] = useState("");

  // Handler for input text change
  const handleInputChange = useCallback((event) => {
    setInputText(event.target.value);
  }, []);

  // Handler for case type change
  const handleCaseChange = useCallback((type) => {
    setCaseType(type);
  }, []);

  // Memoized computation of output text based on input and case type
  const outputText = useMemo(() => {
    if (!inputText.trim()) return "";

    switch (caseType) {
      case "uppercase":
        return inputText.toUpperCase();
      case "lowercase":
        return inputText.toLowerCase();
      case "sentencecase":
        return toSentenceCase(inputText);
      case "titlecase":
        return toTitleCase(inputText);
      default:
        return "";
    }
  }, [inputText, caseType]);

  return (
    <>
      <SEO
        title="Case Changer Tool Online - Convert Text to Uppercase or Lowercase | OnlineToolsFor.com"
        description="Convert text to uppercase, lowercase, or title case with our free online Case Changer Tool. Easy to use, no sign-up required. Try it now at OnlineToolsFor.com."
      />
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">Case Changer Tool</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/web-tools">Web Tools</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Case Changer Tool
            </li>
          </ol>
        </nav>
      </div>
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          <div className="row gx-lg-5">
            <div className="col-md-12">
              <p>
                Our <b>Case Changer tool</b>, available at <b>onlinetoolsfor.com</b>, can quickly convert your text to Uppercase, Lowercase, Sentence Case, or Title Case. Just copy and paste the text you wish to adjust into the tool, pick the ideal change option, and the tool will rapidly convert the text for you. Use it to save time and effort on text conversions!
              </p>
              <br />
              <div className="col-md-10 mx-auto mt-5 form-global">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    value={inputText}
                    onChange={handleInputChange}
                    rows="5"
                    placeholder="Enter your text here..."
                    aria-label="Input text area"
                  ></textarea>
                </div>
                <div className="form-group text-center my-4">
                  <button
                    className="btn btn-primary mr-3"
                    onClick={() => handleCaseChange("uppercase")}
                    disabled={!inputText.trim()}
                    aria-label="Convert to Uppercase"
                  >
                    Uppercase
                  </button>
                  <button
                    className="btn btn-primary mr-3"
                    onClick={() => handleCaseChange("lowercase")}
                    disabled={!inputText.trim()}
                    aria-label="Convert to Lowercase"
                  >
                    Lowercase
                  </button>
                  <button
                    className="btn btn-primary mr-3"
                    onClick={() => handleCaseChange("sentencecase")}
                    disabled={!inputText.trim()}
                    aria-label="Convert to Sentence Case"
                  >
                    Sentence Case
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleCaseChange("titlecase")}
                    disabled={!inputText.trim()}
                    aria-label="Convert to Title Case"
                  >
                    Title Case
                  </button>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    value={outputText}
                    rows="5"
                    readOnly
                    placeholder="Converted text will appear here..."
                    aria-label="Output text area"
                  ></textarea>
                </div>
              </div>
              <br />
              <br />
              <div className="row">
                <div className="col-md-12">
                  <h2>Case Changer Tool: Change Text Case in a Flash</h2>
                  <p>
                    Are you tired of manually changing the case of your text? Whether you want to switch all lowercase text to uppercase or capitalize the first letter of each word, a case changer tool can make the process quicker and more efficient.
                  </p>
                  <p>
                    At its core, a case changer tool is a simple online utility that can quickly modify the case of any text that you input. With just a few clicks, you can change the case of text from lowercase to uppercase, capitalize each word, or even toggle between uppercase and lowercase characters.
                  </p>
                  <h2>What Can You Do With a Case Changer Tool?</h2>
                  <p>The possibilities are endless! Here are just a few examples of how you can use a case changer tool:</p>
                  <ul className="content-listitem">
                    <li>Convert all lowercase text to uppercase for titles and headings</li>
                    <li>Change the case of text to conform with specific style guidelines (e.g., sentence case, title case, etc.)</li>
                    <li>Convert all uppercase text to lowercase for easier readability</li>
                    <li>Capitalize the first letter of each word to enhance the readability of text</li>
                  </ul>
                  <h2>How Does a Case Changer Tool Work?</h2>
                  <p>
                    Using a case changer tool is incredibly simple. You simply enter the text you need to alter, select the desired case transformation, and click the "Change Case" button. The tool will then instantly modify your text based on your selected option.
                  </p>
                  <h2>Why Should You Use a Case Changer Tool?</h2>
                  <p>
                    A case changer tool can save you time and effort when it comes to modifying the case of your text. Instead of manually changing the case of every single letter or word, you can rely on an automated tool to handle the task for you. This can be particularly useful for writers, editors, and anyone who works with text regularly.
                  </p>
                  <h3>In Conclusion</h3>
                  <p>
                    A case changer tool is a valuable online utility that can help you modify the case of any text quickly and efficiently. Whether you want to switch all lowercase text to uppercase or capitalize the first letter of each word, a case changer tool can assist you in achieving your desired outcome with just a few clicks. So why not try one out today and experience the benefits for yourself?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseChanger;
