// InvoiceGenerator.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SEO from "../../../SEO";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "./module.InvoiceGenerator.css"; // Import the CSS file

import BusinessInfo from "./BusinessInfo";
import InvoiceDetails from "./InvoiceDetails";
import CustomerInfo from "./CustomerInfo";
import Items from "./Items";
import PaymentInfo from "./PaymentInfo";
import Summary from "./Summary";

const InvoiceGenerator = () => {
    const [invoiceData, setInvoiceData] = useState({
        business: {
            name: "",
            address: "",
            phone: "",
            email: "",
            logoBase64: "", // Store logo as base64
        },
        invoice: {
            number: "",
            date: "",
            dueDate: "",
        },
        customer: {
            name: "",
            address: "",
            phone: "",
            email: "",
        },
        items: [{ description: "", quantity: "", unitPrice: "" }],
        paymentInfo: {
            methods: "",
            bankDetails: "",
            qrCode: "", // Store QR code as base64
        },
        summary: {
            taxRate: "",
            discount: "",
        },
    });

    const addItem = () => {
        setInvoiceData((prevData) => ({
            ...prevData,
            items: [
                ...prevData.items,
                { description: "", quantity: "", unitPrice: "" },
            ],
        }));
    };

    const removeItem = (index) => {
        setInvoiceData((prevData) => ({
            ...prevData,
            items: prevData.items.filter((_, i) => i !== index),
        }));
    };

    const generatePDF = async () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const margin = 10;

        // Define maximum logo height in mm
        const maxLogoHeight = 40; // Adjust as needed

        let currentY = margin;

        // 1. Invoice Title
        currentY += 10;
        doc.setFontSize(24);
        doc.setTextColor(101,57,192);
        doc.setFont("helvetica", "bold");
        doc.text("INVOICE", margin, currentY);
        doc.setFont("helvetica", "normal");
        currentY += 8;
        doc.setFontSize(10);
        doc.setTextColor(32,23,66);
        doc.text(
            `Invoice No: ${invoiceData.invoice.number || "N/A"}`,
            margin,
            currentY
        );
        currentY += 5;
        doc.text(
            `Invoice Date: ${invoiceData.invoice.date || "N/A"}`,
            margin,
            currentY
        );
        currentY += 5;
        doc.text(
            `Due Date: ${invoiceData.invoice.dueDate || "N/A"}`,
            margin,
            currentY
        );

        // 2. Logo (Right)
        if (invoiceData.business.logoBase64) {
            try {
                const img = new Image();
                img.src = invoiceData.business.logoBase64;
                await new Promise((resolve, reject) => {
                    img.onload = () => resolve();
                    img.onerror = () =>
                        reject(new Error("Failed to load logo image"));
                });

                const imgWidth = img.width;
                const imgHeight = img.height;
                const aspectRatio = imgWidth / imgHeight;

                let scaledHeight = maxLogoHeight;
                let scaledWidth = aspectRatio * scaledHeight;

                // Optional: Define maximum width to prevent excessive width
                const maxLogoWidth = pageWidth / 3 - 2 * margin;
                if (scaledWidth > maxLogoWidth) {
                    scaledWidth = maxLogoWidth;
                    scaledHeight = scaledWidth / aspectRatio;
                }

                doc.addImage(
                    img,
                    "PNG",
                    pageWidth - margin - scaledWidth,
                    currentY - 20, // Adjust Y position to align with Invoice Details
                    scaledWidth,
                    scaledHeight
                );
            } catch (error) {
                console.error("Error loading logo image:", error);
            }
        }

        currentY += 10; // Adjust Y position after logo and Invoice Details

        // Function to draw a rounded rectangle without a border
        const drawRoundedRect = (doc, x, y, width, height, radius) => {
            doc.setFillColor(239, 234, 248); // Set the fill color
            doc.roundedRect(x, y, width, height, radius, radius, 'F'); // 'F' indicates filling the rectangle without border
        };

        // Billed By Card
        const cardHeight = 40; // Height of the card
        const cardRadius = 1; // Radius for the rounded corners

        // Draw the rounded rectangle for "Billed By" section
        drawRoundedRect(doc, margin, currentY, pageWidth / 2 - 15, cardHeight, cardRadius); // Rounded rectangle

        // Set font properties for the "Billed By" text
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Billed By", margin + 5, currentY + 10);

        // Set font properties for the details
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        doc.text(`Name: ${invoiceData.business.name || "N/A"}`, margin + 5, currentY + 18);
        doc.text(`Address: ${invoiceData.business.address || "N/A"}`, margin + 5, currentY + 23);
        doc.text(`Phone: ${invoiceData.business.phone || "N/A"}`, margin + 5, currentY + 28);
        doc.text(`Email: ${invoiceData.business.email || "N/A"}`, margin + 5, currentY + 33);


        // // Billed To Card
        // const cardHeight = 40; // Height of the card
        // const cardRadius = 5; // Radius for the rounded corners

        // Draw the rounded rectangle for "Billed To" section
        drawRoundedRect(doc, pageWidth / 2 + 5, currentY, pageWidth / 2 - 15, cardHeight, cardRadius); // Rounded rectangle

        // Set font properties for the "Billed To" text
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.text("Billed To", pageWidth / 2 + 10, currentY + 10);

        // Set font properties for the details
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        doc.text(`Name: ${invoiceData.customer.name || "N/A"}`, pageWidth / 2 + 10, currentY + 18);
        doc.text(`Address: ${invoiceData.customer.address || "N/A"}`, pageWidth / 2 + 10, currentY + 23);
        doc.text(`Phone: ${invoiceData.customer.phone || "N/A"}`, pageWidth / 2 + 10, currentY + 28);
        doc.text(`Email: ${invoiceData.customer.email || "N/A"}`, pageWidth / 2 + 10, currentY + 33);


        currentY += 47; // Adjust Y position after cards

        // 4. Items Table
        // doc.setFontSize(12);
        // doc.setFont("helvetica", "bold");
        // doc.text("Items", margin, currentY, { align: "left" });
        // currentY += 6;

        const tableColumn = ["Item", "Quantity", "Rate", "Amount"];
        const tableRows = invoiceData.items.map((item) => {
            const description = item.description || "N/A";
            const quantity = parseFloat(item.quantity) || 0;
            const unitPrice = parseFloat(item.unitPrice) || 0;
            const total = quantity * unitPrice;
            return [
                description,
                quantity.toString(),
                unitPrice.toFixed(2),
                total.toFixed(2),
            ];
        });

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: currentY,
            theme: "striped",
            headStyles: {
                fillColor: [101, 57, 192],
                textColor: [255, 255, 255],
                fontSize: 10,
                halign: "left",
            },
            bodyStyles: {
                fontSize: 10,
                halign: "left",
            },
            styles: {
                cellPadding: 2,
                overflow: "linebreak",
                minCellHeight: 8,
            },
            alternateRowStyles: { fillColor: [239, 234, 248] },
            margin: { top: 10, bottom: 10, left: margin, right: margin },
            tableWidth: pageWidth - 2 * margin,
        });

        currentY = doc.lastAutoTable.finalY + 12;

        // 5. Summary
        const subtotal = invoiceData.items.reduce(
            (acc, item) =>
                acc +
                (parseFloat(item.quantity) || 0) *
                    (parseFloat(item.unitPrice) || 0),
            0
        );
        const taxAmount =
            (subtotal * (parseFloat(invoiceData.summary.taxRate) || 0)) / 100;
        const totalAmount =
            subtotal +
            taxAmount -
            (parseFloat(invoiceData.summary.discount) || 0);

        // Update summary in invoiceData
        setInvoiceData((prevData) => ({
            ...prevData,
            summary: {
                ...prevData.summary,
                subtotal: subtotal.toFixed(2),
                taxAmount: taxAmount.toFixed(2),
                totalAmount: totalAmount.toFixed(2),
            },
        }));

        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Summary", margin, currentY);
        doc.setFont("helvetica", "normal");
        currentY += 7;
        doc.setFontSize(10);
        doc.text(`Subtotal: ${subtotal.toFixed(2)}`, margin, currentY);
        currentY += 6;
        doc.text(
            `Tax Rate: ${(parseFloat(invoiceData.summary.taxRate) || 0).toFixed(
                2
            )}%`,
            margin,
            currentY
        );
        currentY += 6;
        doc.text(`Tax Amount: ${taxAmount.toFixed(2)}`, margin, currentY);
        currentY += 6;
        doc.text(
            `Discount: ${(
                parseFloat(invoiceData.summary.discount) || 0
            ).toFixed(2)}`,
            margin,
            currentY
        );
        currentY += 10;

        // **Styling the "Total Amount Due" Text with lines above and below**
        doc.setDrawColor(101,57,192); // Set the line color to blue
        doc.setLineWidth(0.3); // Set the line thickness

        // Line above the text
        doc.line(margin, currentY - 3, pageWidth - margin, currentY - 3); // Draw a horizontal line above
        currentY += 4;

        // "Total Amount Due" text
        doc.setFontSize(12); // Larger font size for emphasis
        doc.setFont("helvetica", "bold"); // Bold font style
        doc.setTextColor(101,57,192); // Set text color to blue
        doc.text(
            `Total Amount Due: ${totalAmount.toFixed(2)}`,
            margin,
            currentY
        );

        // Line below the text
        currentY += 1; // Move down for the next line
        doc.line(margin, currentY + 3, pageWidth - margin, currentY + 3 ); // Draw a horizontal line below



        // 6. Payment Information
        currentY += 12;
        doc.setTextColor(32,23,66);
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Payment Information", margin, currentY);
        doc.setFont("helvetica", "normal");
        currentY += 6;
        doc.setFontSize(10);
        doc.text(
            `Payment Methods: ${invoiceData.paymentInfo.methods || "N/A"}`,
            margin,
            currentY
        );
        currentY += 5;
        
        doc.text(
            `Bank Details: ${invoiceData.paymentInfo.bankDetails || "N/A"}`,
            margin,
            currentY,
            { maxWidth: 180 } // Adjust maxWidth based on your desired wrapping width
        );

        // Add QR Code Image if available
        // if (invoiceData.paymentInfo.qrCode) {
        //   try {
        //     const qrImg = new Image();
        //     qrImg.src = invoiceData.paymentInfo.qrCode;
        //     await new Promise((resolve, reject) => {
        //       qrImg.onload = () => resolve();
        //       qrImg.onerror = () =>
        //         reject(new Error("Failed to load QR code image"));
        //     });

        //     const qrWidth = qrImg.width;
        //     const qrHeight = qrImg.height;
        //     const qrAspectRatio = qrWidth / qrHeight;

        //     // Define maximum QR code size
        //     const maxQRSize = 50; // in mm, adjust as needed

        //     let scaledQRHeight = maxQRSize;
        //     let scaledQRWidth = qrAspectRatio * scaledQRHeight;

        //     // Adjust if width exceeds available space
        //     if (scaledQRWidth > 50) {
        //       // assuming max width for QR code is 50mm
        //       scaledQRWidth = 50;
        //       scaledQRHeight = scaledQRWidth / qrAspectRatio;
        //     }

        //     doc.addImage(
        //       qrImg,
        //       "PNG",
        //       margin,
        //       currentY + 10,
        //       scaledQRWidth,
        //       scaledQRHeight
        //     );
        //   } catch (error) {
        //     console.error("Error loading QR code image:", error);
        //   }
        // }

        // 7. Footer Information
        doc.setFontSize(7);
        doc.setFont("helvetica", "normal");
        doc.text(
            "This is an electronically generated document, no signature is required.",
            pageWidth / 2,
            pageHeight - 10,
            { align: "center" }
        );

        // Save PDF
        doc.save(`Invoice_${invoiceData.invoice.number || "001"}.pdf`);
    };

  return (
    <>
      <SEO
        title="Free Invoice Generator - Create Professional Invoices Quickly | OnlineToolsFor.com"
        description="Generate professional invoices effortlessly with our user-friendly Invoice Generator. Perfect for freelancers and small businesses, our online tool allows you to customize your invoices with ease, ensuring you get paid on time. Start creating your invoices now!"
    />

      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">Invoice Generator</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/web-tools">Web Tools</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
                Invoice Generator
            </li>
          </ol>
        </nav>
      </div>
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          {/* Page Features */}
          <div className="row gx-lg-5">
            <p>
            Use our <b>Invoice Generator</b> to create professional invoices quickly and accurately. Just enter your business details, customer information, and itemized charges, and the tool will calculate totals, taxes, and discounts automatically. This ensures your invoices are clear, error-free, and ready to send. Simplify your invoicing process and boost efficiency—try it today on <b>onlinetoolsfor.com!</b>
            </p>
            <br />
            <br />
            <div className="col-md-12">
                <div className="invoice-generator mb-5">
                    <h2 className="title">Invoice Generator</h2>

                    {/* Business Information */}
                    <BusinessInfo
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData}
                    />

                    {/* Invoice Details */}
                    <InvoiceDetails
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData}
                    />

                    {/* Customer Information */}
                    <CustomerInfo
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData}
                    />

                    {/* Items Section */}
                    <Items
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData}
                        addItem={addItem}
                        removeItem={removeItem}
                    />

                    {/* Summary Section */}
                    <Summary
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData}
                    />

                    {/* Payment Information */}
                    <PaymentInfo
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData}
                    />

                    <button className="float-end" onClick={generatePDF}>Generate PDF</button>
                    <br />
                    <br />
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h2>
                Invoice Generator: Streamline Your Invoicing Process
              </h2>
              <p>
                Managing invoices can be tedious, but an invoice generator simplifies it, helping you create professional invoices quickly and accurately.
              </p>
              <h2>Why Use an Invoice Generator?</h2>
              <ul className="content-listitem">
                <li><b>Save Time:</b> Easily create and customize invoices without manually formatting documents.</li>
                <li><b>Professional Look:</b> Generate clean, branded invoices with your company logo, details, and payment info.</li>
                <li><b>Automated Calculations:</b> Automatically calculate totals, taxes, and discounts, minimizing errors.</li>
                <li><b>Track Payments:</b> Include payment methods and due dates to keep track of transactions efficiently.</li>
              </ul>
              <h2>Key Features of Our Invoice Generator</h2>
              <ul className="content-listitem">
                <li><b>Business Information:</b> Input your company details, including logo, address, and contact info.</li>
                <li><b>Invoice Details:</b> Add invoice numbers, dates, and due dates for easy reference.</li>
                <li><b>Customer Info:</b> Easily fill in client details like name, address, and email.</li>
                <li><b>Items and Pricing:</b> List items with descriptions, quantities, and prices. The tool calculates totals automatically.</li>
                <li><b>Tax and Discount:</b> Apply tax rates and discounts, with real-time updates to the total amount.</li>
                <li><b>Payment Information:</b> Provide payment methods and include QR codes or bank details for quick transactions.</li>
              </ul>
              <h2>How It Works</h2>
              <ul className="content-listitem">
                <li><b>Input Business and Customer Info:</b> Fill out your business and client information for a professional touch.</li>
                <li><b>Add Items and Pricing:</b> Add product descriptions, set quantities and unit prices—totals will be calculated instantly.</li>
                <li><b>Customize Payment Details:</b> Add tax, discounts, and payment options. Generate a PDF with one click and send it directly to your customer.</li>
                <li><b>Download and Share:</b> Save or print the invoice in PDF format for easy distribution.</li>
              </ul>
              <h2>Conclusion</h2>
              <p>An invoice generator is essential for businesses that want to streamline their billing process. With automated calculations, customization options, and easy-to-use features, you can create professional invoices in minutes, saving time and reducing errors.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvoiceGenerator;
