// CssGeneratorsCardParent.js
import React from "react";
import CssGeneratorsCard from "./CssGeneratorsCard";

const CssGeneratorsCardData = [
  {
    iconClass: "fas fa-fill-drip",
    title: "CSS Gradient Generator",
    link: "/css-generators/css-gradient-generator",
    description:
      "Generate beautiful CSS gradients easily with our tool. Linear or radial options with presets.",
  },
  {
    iconClass: "fas fa-cube",
    title: "CSS Box Shadow Generator",
    link: "/css-generators/css-boxshadow-generator",
    description:
      "Generate CSS box shadows easily: customize blur, spread, color, and offset for perfect effect.",
  },
  {
    iconClass: "fas fa-angle-double-right",
    title: "CSS Border Radius Generator",
    link: "/css-generators/css-border-radius-generator",
    description:
      "Generate perfect effects: customize box corner radius with CSS Border Radius Generator.",
  },
  {
    iconClass: "fas fa-hand-pointer",
    title: "css-button-generator",
    link: "/css-generators/css-button-generator",
    description:
      "Create custom buttons with our CSS Button Generator. Customize colors, borders, and more for a unique look. Try it now!",
  },
  {
    iconClass: "fas fa-angle-right",
    title: "CSS Triangle Generator",
    link: "/css-generators/css-triangle-generator",
    description:
      "Create customizable triangles with CSS Triangle Generator - Ultimate tool for web designers!",
  },
  // {
  //   iconClass: "bi bi-border",
  //   title: "CSS Sprite Generator",
  //   link: "/css-generators/css-sprite-generator",
  //   description:
  //     "Create faster loading websites with CSS sprites. Try a free generator tool today!",
  // },

  // Add more data items as needed
];

const CssGeneratorsCardParent = () => {
  return (
    <div className="row">
      {CssGeneratorsCardData.map((feature, index) => (
        <CssGeneratorsCard key={index} {...feature} />
      ))}
    </div>
  );
};

export default CssGeneratorsCardParent;
