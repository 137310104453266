// TriangleGenerator.jsx
import React, { useState, useEffect, useRef } from "react";
import "./triangle-generator.css";
import SEO from "../../../SEO";
import { Link } from "react-router-dom";
import Prism from "prismjs";
import "prismjs/themes/prism.css"; // Import Prism CSS theme
import "prismjs/components/prism-css"; // Import CSS syntax highlighting

const TriangleGenerator = () => {
  const triangleRef = useRef(null);
  const [val, setVal] = useState({
    size: 200,
    height: 0.5,
    angle: 0,
  });

  const [bdr, setBdr] = useState(["top", "right", "bottom", "left"]);
  const [dir, setDir] = useState("top");
  const [cssStyle, setCssStyle] = useState("");

  const [cssCode, setCssCode] = useState("");
  const codeRef = useRef(null);

  useEffect(() => {
    draw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val, dir]); // Removed showBorders as it's currently not in use

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVal({
      ...val,
      [name]: parseFloat(value),
    });
  };

  const handlePresetButtonClick = (preset) => {
    setVal({
      ...val,
      [preset.property]: preset.value,
    });
  };

  const handleDirectionChange = (e) => {
    const selectedDir = e.target.value;
    setDir(selectedDir);
    setBdr((prevBdr) => {
      const newBdr = [...prevBdr];
      while (newBdr[0] !== selectedDir) {
        newBdr.push(newBdr.shift());
      }
      return newBdr;
    });
  };

  const draw = () => {
    let style = "";
    const css = {
      width: "0",
      height: "0",
      border: "0 solid transparent",
    };
    css[`border-${bdr[1]}-width`] = `${Math.round((val.size * (-val.angle + 1)) / 2)}px`;
    css[`border-${bdr[3]}-width`] = `${Math.round((val.size * (val.angle + 1)) / 2)}px`;
    css[`border-${bdr[2]}`] = `${Math.round(val.size * val.height)}px solid black`;
    css["height"] = "0";
    css["width"] = "0";

    const A = (val.size * (val.angle + 1)) / 2;
    const B = val.size * val.height;
    const C = (val.size * (-val.angle + 1)) / 2;

    const hypotenuse = Math.round(Math.sqrt(A * A + B * B));

    const inner = hypotenuse * hypotenuse - C * C;

    let opposite;
    if (inner >= 0) {
      opposite = Math.round(Math.sqrt(inner));
    } else {
      console.warn(
        `Invalid triangle dimensions: inner value is negative (${inner}). Setting width to 0.`
      );
      opposite = 0;
    }

    css["height"] = `${Math.round(B)}px`;
    css["width"] = `${opposite}px`;

    if (triangleRef.current) {
      Object.assign(triangleRef.current.style, css);
    }

    // Generate plain CSS code without manual span styling
    const borderStyle = `border-${bdr[1]}-width: ${Math.round(
      (val.size * (-val.angle + 1)) / 2
    )}px;\nborder-${bdr[3]}-width: ${Math.round(
      (val.size * (val.angle + 1)) / 2
    )}px;\nborder-${bdr[2]}: ${Math.round(val.size * val.height)}px solid black;\nheight: ${Math.round(
      B
    )}px;\nwidth: ${opposite}px;`;

    const webkitBorderStyle = `-webkit-${borderStyle}`;
    const mozBorderStyle = `-moz-${borderStyle}`;

    const completeCss = `${borderStyle}\n${webkitBorderStyle}\n${mozBorderStyle}`;

    setCssCode(completeCss);
  };

  const handleCopyCode = () => {
    if (codeRef.current) {
      navigator.clipboard
        .writeText(cssCode)
        .then(() => {
          // Optionally, provide feedback to the user
          alert("CSS code copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          alert("Failed to copy CSS code. Please try again.");
        });
    }
  };

  useEffect(() => {
    Prism.highlightAll();
  }, [cssCode]);

  return (
    <>
      <SEO
        title="CSS Triangle Generator - OnlineToolsFor.com"
        description="Easily create and customize triangles for your website or project using CSS Triangle Generator, a free online tool available on OnlineToolsFor.com. Select the size, color, border, and positioning of your triangle, and generate the corresponding CSS code with just a few clicks. Perfect for both novice and professional designers, CSS Triangle Generator streamlines the process of creating beautiful, customized triangles. Try it out today and add a touch of uniqueness to your design!"
      />

      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">CSS Triangle Generator</h1>
            </div>
          </div>
        </div>
      </header>

      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/css-generators">CSS Generators</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              CSS Triangle Generator
            </li>
          </ol>
        </nav>
      </div>

      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          <div className="gx-lg-5">
            <p>
              <b>CSS Triangle Generator</b> is a free online tool available on{" "}
              <b>onlinetoolsfor.com</b> that allows users to create customizable
              triangles using CSS. This simple tool permits you to alter the
              size, variety, border, and placement of your triangle prior to
              producing CSS code that you can easily copy and paste into your
              project. Whether you're an expert website designer or just getting
              started with coding, CSS Triangle Generator is an excellent asset
              that can save you time and hassle when it comes to making the
              ideal triangle. This tool is destined to turn into a go-to asset
              for anyone wishing to add a customized triangle to their website
              or undertaking, because of its simple plan and robust
              functionality. Try CSS Triangle Generator today and start creating
              beautiful, customized triangles in no time!
            </p>
            <br />
            <br />
            <br />

            <div className="col-md-12">
              <div className="row">
                {/* Controls Section */}
                <div className="col-md-6">
                  <div className="ctrl">
                    <ul id="values">
                      {/* Size Control */}
                      <li>
                        <label htmlFor="size">
                          <span>
                            <b>Size</b>
                          </span>
                          <input
                            id="size"
                            name="size"
                            type="range"
                            min="0"
                            max="400"
                            step="1"
                            value={val.size}
                            onChange={handleInputChange}
                          />
                          <span aria-live="polite">{val.size}px</span>
                        </label>
                      </li>

                      {/* Height Control with Presets */}
                      <li>
                        <label htmlFor="height">
                          <span>
                            <b>Height</b>
                          </span>
                          <input
                            id="height"
                            name="height"
                            type="range"
                            min="0"
                            max="2"
                            step="0.001"
                            value={val.height}
                            onChange={handleInputChange}
                          />
                          <span aria-live="polite">{val.height}</span>
                          <div className="preset-buttons">
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm me-1"
                              onClick={() =>
                                handlePresetButtonClick({
                                  property: "height",
                                  value: 0.5,
                                })
                              }
                            >
                              1:2
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm me-1"
                              onClick={() =>
                                handlePresetButtonClick({
                                  property: "height",
                                  value: 1,
                                })
                              }
                            >
                              1:1
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm"
                              onClick={() =>
                                handlePresetButtonClick({
                                  property: "height",
                                  value: 0.866,
                                })
                              }
                            >
                              √3:2 (Equilateral)
                            </button>
                          </div>
                        </label>
                      </li>

                      {/* Angle Control */}
                      <li>
                        <label htmlFor="angle">
                          <span>
                            <b>Angle</b>
                          </span>
                          <input
                            id="angle"
                            name="angle"
                            type="range"
                            min="-1"
                            max="1"
                            step="0.001"
                            value={val.angle}
                            onChange={handleInputChange}
                          />
                          <span aria-live="polite">{val.angle}</span>
                        </label>
                      </li>
                    </ul>

                    {/* Direction Control */}
                    <ul id="direction" className="mt-3">
                      <li className="d-inline-block me-3">
                        <label htmlFor="dir-top">
                          <input
                            id="dir-top"
                            type="radio"
                            name="dir"
                            value="top"
                            checked={dir === "top"}
                            onChange={handleDirectionChange}
                          />
                          <span role="img" aria-label="Up">
                            ↑
                          </span>
                        </label>
                      </li>
                      <li className="d-inline-block me-3">
                        <label htmlFor="dir-right">
                          <input
                            id="dir-right"
                            type="radio"
                            name="dir"
                            value="right"
                            checked={dir === "right"}
                            onChange={handleDirectionChange}
                          />
                          <span role="img" aria-label="Right">
                            →
                          </span>
                        </label>
                      </li>
                      <li className="d-inline-block me-3">
                        <label htmlFor="dir-bottom">
                          <input
                            id="dir-bottom"
                            type="radio"
                            name="dir"
                            value="bottom"
                            checked={dir === "bottom"}
                            onChange={handleDirectionChange}
                          />
                          <span role="img" aria-label="Down">
                            ↓
                          </span>
                        </label>
                      </li>
                      <li className="d-inline-block">
                        <label htmlFor="dir-left">
                          <input
                            id="dir-left"
                            type="radio"
                            name="dir"
                            value="left"
                            checked={dir === "left"}
                            onChange={handleDirectionChange}
                          />
                          <span role="img" aria-label="Left">
                            ←
                          </span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Triangle Display Section */}
                <div className="col-md-6">
                  {/* Uncomment and implement showBorders functionality if needed
                  <div className="show-borders-control">
                    <input
                      id="show"
                      type="checkbox"
                      name="showBorders"
                      checked={showBorders}
                      onChange={() => setShowBorders((prev) => !prev)}
                    />
                    <label htmlFor="show">Show All Borders</label>
                  </div>
                  */}
                  <div className="out d-flex justify-content-center align-items-center">
                    <div className="tri" ref={triangleRef}></div>
                  </div>
                </div>
              </div>

              {/* CSS Code Display */}
              <div className="row mt-4">
                <div className="col-md-12" id="result">
                  <h4>CSS Code:</h4>
                  <pre>
                    <code className="language-css" ref={codeRef}>
                      {cssCode}
                    </code>
                  </pre>
                  <button
                    className="btn btn-primary mt-2 float-end"
                    onClick={handleCopyCode}
                  >
                    Copy Code
                  </button>
                </div>
              </div>
              <br />
              <br />

              {/* Informational Content */}
              <div className="row">
                <div className="col-md-12">
                  <h2>
                    CSS Triangle Generator: Create Unique Shapes for Your Web
                    Projects
                  </h2>
                  <p>
                    Are you tired of using the same old rectangular and square
                    shapes on your website? Would you like to infuse your designs
                    with a hint of creativity? Look no further than the CSS Triangle
                    Generator! With the help of this effective tool, you can easily
                    generate personalized triangles with just a few clicks. In this
                    article, we will discuss the advantages of utilizing CSS
                    triangles and guide you on utilizing the CSS Triangle Generator
                    to craft your very own distinctive shapes.
                  </p>

                  <h2>What Are CSS Triangles?</h2>
                  <p>
                    CSS triangles are shapes created entirely with CSS. They are
                    typically used to create arrows, chevrons, and other geometric
                    shapes. Unlike traditional images, CSS triangles can be resized,
                    recolored, and manipulated using CSS. This makes them ideal for
                    responsive web design and other dynamic web projects.
                  </p>

                  <h2>Why Use CSS Triangles?</h2>
                  <p>
                    CSS triangles have a number of advantages over traditional
                    images. For one, they are much lighter and faster to load. CSS
                    triangles can greatly enhance your website's performance,
                    particularly on mobile devices. They offer easy customization
                    options as they are created using CSS. Harnessing the might of
                    just a few lines of code, you can effortlessly tailor the size,
                    color, and position of your CSS triangles to your liking.
                    Moreover, CSS triangles are scalable, ensuring they appear
                    visually appealing on any device, regardless of screen size or
                    resolution.
                  </p>

                  <h2>How to Use the CSS Triangle Generator</h2>
                  <p>
                    Using the CSS Triangle Generator is easy. Simply visit the
                    website and choose your desired triangle shape. You can select
                    different pre-made shapes like equilateral triangles, isosceles
                    triangles, and right triangles. After selecting your desired
                    shape, you can further customize it by adjusting its size.
                  </p>

                  <h2>Conclusion</h2>
                  <p>
                    For web designers seeking to craft distinctive and dynamic
                    shapes on their websites, CSS triangles serve as a powerful
                    tool. With the CSS Triangle Generator, creating custom triangles
                    has never been easier. By implementing the guidance and
                    strategies provided in this article, you can craft captivating
                    and adaptable designs that will amaze your visitors and enhance
                    your website's performance.
                  </p>
                </div>
              </div>
            </div>
            </div>
          </div>
      </section>
    </>
  );
};

export default TriangleGenerator;
