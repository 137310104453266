import React, { useState, useCallback } from "react";
import "./loan-calculator.css"; // Ensure you have appropriate styling
import SEO from "../../../SEO";
import { Link } from "react-router-dom";

// Utility function to format numbers as currency
const formatCurrency = (num) => {
  return num.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

// Loan calculation functions moved outside the component for stability
const calculateFixedInterest = (principal, annualInterestRate, years) => {
  const monthlyInterestRate = annualInterestRate / 100 / 12;
  const totalPayments = years * 12;
  const x = Math.pow(1 + monthlyInterestRate, totalPayments);
  const monthly = (principal * x * monthlyInterestRate) / (x - 1);
  const total = monthly * totalPayments;
  const totalInterest = total - principal;

  return {
    monthlyPayment: monthly,
    totalPayment: total,
    totalInterest: totalInterest,
  };
};

const calculateFloatingInterest = (principal, annualInterestRate, years) => {
  const monthlyInterestRate = annualInterestRate / 100 / 12;
  const totalPayments = years * 12;
  let totalInterestPaid = 0;
  let remainingPrincipal = principal;

  for (let i = 0; i < totalPayments; i++) {
    const monthlyInterest = remainingPrincipal * monthlyInterestRate;
    totalInterestPaid += monthlyInterest;
    remainingPrincipal -= (monthlyInterest) ? 0 : 0; // Placeholder for actual floating rate calculation
  }

  const total = principal + totalInterestPaid;
  const monthly = total / totalPayments;

  return {
    monthlyPayment: monthly,
    totalPayment: total,
    totalInterest: totalInterestPaid,
  };
};

const LoanCalculator = () => {
  const [amount, setAmount] = useState("");
  const [interest, setInterest] = useState("");
  const [years, setYears] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState("");
  const [totalPayment, setTotalPayment] = useState("");
  const [totalInterest, setTotalInterest] = useState("");
  const [interestType, setInterestType] = useState("fixed");
  const [error, setError] = useState("");

  // Input change handlers
  const handleAmountChange = useCallback((e) => setAmount(e.target.value), []);
  const handleInterestChange = useCallback((e) => setInterest(e.target.value), []);
  const handleYearsChange = useCallback((e) => setYears(e.target.value), []);
  const handleInterestTypeChange = useCallback((e) => setInterestType(e.target.value), []);

  // Function to calculate results
  const calculateResults = useCallback(() => {
    // Input validation
    if (!amount || !interest || !years) {
      setError("Please enter all fields.");
      return;
    }

    const principal = parseFloat(amount);
    const annualInterestRate = parseFloat(interest);
    const loanYears = parseFloat(years);

    if (isNaN(principal) || isNaN(annualInterestRate) || isNaN(loanYears)) {
      setError("Please enter valid numbers.");
      return;
    }

    if (principal <= 0 || annualInterestRate <= 0 || loanYears <= 0) {
      setError("Please enter positive numbers.");
      return;
    }

    let results;

    if (interestType === "fixed") {
      results = calculateFixedInterest(principal, annualInterestRate, loanYears);
    } else if (interestType === "floating") {
      results = calculateFloatingInterest(principal, annualInterestRate, loanYears);
    } else {
      setError("Invalid interest type selected.");
      return;
    }

    if (isFinite(results.monthlyPayment)) {
      setMonthlyPayment(formatCurrency(results.monthlyPayment));
      setTotalPayment(formatCurrency(results.totalPayment));
      setTotalInterest(formatCurrency(results.totalInterest));
      setError("");
    } else {
      setError("Calculation resulted in an invalid number. Please check your inputs.");
    }
  }, [amount, interest, years, interestType]);

  // Function to clear results and inputs (optional)
  const clearResults = useCallback(() => {
    setAmount("");
    setInterest("");
    setYears("");
    setMonthlyPayment("");
    setTotalPayment("");
    setTotalInterest("");
    setError("");
  }, []);

  return (
    <>
      <SEO
        title="Loan Calculator - Calculate Your Monthly Payments and Interest Rates | onlinetoolsfor.com"
        description="Use our free Loan Calculator to easily calculate your monthly payments and interest rates for various types of loans, including mortgages, auto loans, and personal loans. Simply enter the loan amount, interest rate, and loan term, and our calculator will do the rest. Our Loan Calculator also includes amortization tables and graphs to help you visualize your repayment schedule. Try it out today on onlinetoolsfor.com!"
      />
      <header className="pt-5">
        <div className="container px-lg-5">
          <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
            <div className="m-4 m-lg-5">
              <h1 className="display-5 fw-bold">Loan Calculator</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container px-lg-5">
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/online-calculators">Online Calculators</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Loan Calculator
            </li>
          </ol>
        </nav>
      </div>
      {/* <!-- Page Content--> */}
      <section className="pt-4 pb-5">
        <div className="container px-lg-5">
          {/* <!-- Page Features--> */}
          <div className="row gx-lg-5">
            <div className="col-md-12">
              <p className="mb-4">
                Welcome to <b>onlinetoolsfor.com</b>'s <b>loan calculator</b> page! We are delighted to offer you a user-friendly tool that simplifies loan calculations. With our loan calculator, you can effortlessly determine your monthly payment, total interest, and repayment amount. In this informative piece, we will delve into the workings of our calculator, its advantages, and why it serves as an indispensable asset for individuals seeking a loan.
              </p>
              <div className="col-md-8 mx-auto">
                <div>
                  <form id="loan-form" className="form-global">
                    <div className="form-group mb-3">
                      <label htmlFor="amount">Loan Amount</label>
                      <input
                        type="number"
                        className="form-control"
                        id="amount"
                        placeholder="Enter loan amount"
                        value={amount}
                        onChange={handleAmountChange}
                        min="0"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="interest">Interest Rate (%)</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          id="interest"
                          step="0.01"
                          placeholder="Enter interest rate"
                          value={interest}
                          onChange={handleInterestChange}
                          min="0"
                        />
                        <div className="input-group-append">
                          <select
                            className="form-select"
                            id="interest-type"
                            value={interestType}
                            onChange={handleInterestTypeChange}
                          >
                            <option value="fixed">Fixed</option>
                            <option value="floating">Floating</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="years">Loan Term (Years)</label>
                      <input
                        type="number"
                        className="form-control"
                        id="years"
                        placeholder="Enter loan term in years"
                        value={years}
                        onChange={handleYearsChange}
                        min="0"
                      />
                    </div>
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    <div className="form-group mb-3">
                      <button
                        type="button"
                        className="btn btn-primary btn-block float-end"
                        onClick={calculateResults}
                        disabled={!amount || !interest || !years}
                      >
                        Calculate
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btn-block float-end me-2"
                        onClick={clearResults}
                        disabled={!amount && !interest && !years}
                      >
                        Clear
                      </button>
                      <br />
                    </div>
                  </form>
                  <div className="results mt-4">
                    <h5>Results</h5>
                    <div className="form-group mb-3">
                      <label htmlFor="monthly-payment">Monthly Payment</label>
                      <input
                        type="text"
                        className="form-control"
                        id="monthly-payment"
                        disabled
                        value={monthlyPayment}
                        placeholder="Monthly Payment"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="total-payment">Total Payment</label>
                      <input
                        type="text"
                        className="form-control"
                        id="total-payment"
                        disabled
                        value={totalPayment}
                        placeholder="Total Payment"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="total-interest">Total Interest</label>
                      <input
                        type="text"
                        className="form-control"
                        id="total-interest"
                        disabled
                        value={totalInterest}
                        placeholder="Total Interest"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="row">
                <div className="col-md-12">
                  <h2>Understanding Loan Calculators</h2>
                  <p>
                    A loan calculator proves to be a useful instrument that aids in approximating loan payments by taking into consideration variables such as the loan amount, interest rate, and duration of repayment. At onlinetoolsfor.com, we have developed a loan calculator that delivers accurate and reliable calculations, helping you gain insights into the financial impact of your loan.
                  </p>

                  <h2>How to Use Our Loan Calculator</h2>
                  <p>Using our loan calculator is a breeze! Follow these simple steps to calculate your loan payments:</p>

                  <h3>Step 1: Enter the Loan Amount</h3>
                  <p>
                    Begin by entering the desired loan amount you wish to borrow. Whether it's the full loan amount or a specific portion, our calculator adapts to your needs.
                  </p>

                  <h3>Step 2: Enter the Interest Rate</h3>
                  <p>
                    Next, input the interest rate associated with your loan. This rate can be either fixed or variable, depending on your loan terms.
                  </p>

                  <h3>Step 3: Enter the Loan Term</h3>
                  <p>
                    Specify the duration over which you plan to repay the loan. This timeframe, known as the loan term or repayment period, plays a significant role in determining your payment schedule.
                  </p>

                  <h3>Step 4: Click Calculate</h3>
                  <p>
                    Upon inputting the essential information, simply click the designated "Calculate" button to initiate the computation process. Our loan calculator will instantly generate your monthly payment, total interest, and repayment amount, providing you with valuable information to make informed financial decisions.
                  </p>

                  <h2>Advantages of Our Loan Calculator</h2>
                  <p>
                    Our loan calculator offers numerous advantages, making it an indispensable tool for your financial planning:
                  </p>

                  <h3>User-Friendly Interface</h3>
                  <p>
                    We have designed our loan calculator with simplicity in mind. Its intuitive interface ensures that anyone, regardless of their financial expertise, can navigate and utilize it effortlessly.
                  </p>

                  <h3>Accurate Results</h3>
                  <p>
                    Precision is crucial when it comes to financial calculations. Utilizing our loan calculator grants you access to accurate projections of your monthly payment, total interest, and overall repayment sum, empowering you with a comprehensive comprehension of the financial ramifications entailed by your loan.
                  </p>

                  <h3>Time-Saving Tool</h3>
                  <p>
                    Gone are the days of manual calculations and complex formulas. Our loan calculator saves you valuable time by delivering instant results, eliminating the need for laborious computations.
                  </p>

                  <h3>Conclusion</h3>
                  <p>
                    In conclusion, our user-friendly loan calculator is a powerful tool that simplifies the loan planning process. By utilizing our calculator, you can accurately determine your monthly payments, total interest, and repayment amount, empowering you to make well-informed financial decisions. Whether you are budgeting, comparing loan options, or strategizing your repayment, our loan calculator is here to assist you.
                  </p>

                  <p>
                    Visit onlinetoolsfor.com's loan calculator page today and experience hassle-free loan planning. We appreciate your trust in our services and hope that our loan calculator provides you with the necessary insights to achieve your financial goals. Take control of your loan planning with ease by using our loan calculator now!
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
    </>
  );
};

export default LoanCalculator;
