// SeoToolsCardParent.js
import React from "react";
import SeoToolsCard from "./SeoToolsCard";

const SeoToolsCardData = [
  {
    iconClass: "fa-solid fa-link",
    title: "Text to Slug",
    link: "/seo-tools/text-to-slug",
    description:
      "Transform long text to short, SEO-friendly slugs with customizable character limit. Quick and easy conversion for optimal URL readability.",
  },
  {
    iconClass: "fas fa-solid fa-heading",
    title: "Title & Description Tool",
    link: "/seo-tools/title-description-tool",
    description:
      "Craft compelling page titles & meta descriptions. Boost click-through rates with accurate and concise previews. Elevate your SEO game today.",
  },

  // Add more data items as needed
];

const SeoToolsCardParent = () => {
  return (
    <div className="row">
      {SeoToolsCardData.map((feature, index) => (
        <SeoToolsCard key={index} {...feature} />
      ))}
    </div>
  );
};

export default SeoToolsCardParent;
