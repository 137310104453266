import React from 'react';
import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/styles.css'; // Adjust the path based on your structure

// Import Components
import Navigation from "./Navigation";
import Footer from "./Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Webtools from "./pages/Webtools";
import Privacypolicy from "./pages/Privacypolicy";
import TermsConditions from "./pages/TermsConditions";
import Disclaimer from "./pages/Disclaimer"; // Corrected Import
import ContactForm from "./pages/ContactForm";
import CssGenerators from "./pages/CssGenerators";
import SeoTools from "./pages/SeoTools";
import OnlineCalculators from "./pages/OnlineCalculators";
import WordCharacterCounter from "./pages/web-tools/word-character-counter/WordCharacterCounter";
import CaseChanger from "./pages/web-tools/case-changer-tool/CaseChanger";
import CssMinifierTool from "./pages/web-tools/css-minifier/CssMinifierTool";
import CssBeautifier from "./pages/web-tools/css-beautifier-tool/CssBeautifier";
import ExcelConverter from "./pages/web-tools/php-array-to-excel/ExcelConverter";
// import DateConverterForm from "./pages/web-tools/php-date-format-code-generator/DateConverterForm";
import ImageToWebPConverter from "./pages/web-tools/image-to-webp-converter/ImageToWebPConverter";
import InvoiceGenerator from "./pages/web-tools/invoice-generator/InvoiceGenerator";

import BorderRadiusControl from "./pages/css-generators/css-border-radius-generator/BorderRadiusControl";
import BoxShadowGenerator from "./pages/css-generators/css-boxshadow-generator/BoxShadowGenerator";
import TriangleGenerator from "./pages/css-generators/css-triangle-generator/TriangleGenerator";
import TitleDescriptionTool from "./pages/seo-tools/title-description-tool/TitleDescriptionTool";
import SlugConverter from "./pages/seo-tools/text-to-slug/SlugConverter";
import AgeCalculator from "./pages/online-calculators/age-calculator/AgeCalculator";
import LoanCalculator from "./pages/online-calculators/loan-calculator/LoanCalculator";
import PayPalCalculator from "./pages/online-calculators/paypal-fee-calculator/PayPalCalculator";
import PercentageCalculator from "./pages/online-calculators/percentage-calculator/PercentageCalculator";
import MutualFundCalculator from './pages/online-calculators/mutual-fund-calculator/MutualFundCalculator';
import BMICalculator from './pages/online-calculators/bmi-calculator/BMICalculator';
import ButtonStyler from "./pages/css-generators/css-button-generator/ButtonStyler";
import GradientGenerator from "./pages/css-generators/css-gradient-generator/GradientGenerator";
import NotFound from './NotFound';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Routes>
        {/* Home Route */}
        <Route path="/" element={<Home />} />

        {/* About Page */}
        <Route path="/about" element={<About />} />

        {/* Web Tools */}
        <Route path="/web-tools" element={<Webtools />} />
        <Route path="/web-tools/css-beautifier-tool" element={<CssBeautifier />} />
        <Route path="/web-tools/word-character-counter" element={<WordCharacterCounter />} />
        <Route path="/web-tools/case-changer-tool" element={<CaseChanger />} />
        <Route path="/web-tools/css-minifier-tool" element={<CssMinifierTool />} />
        <Route path="/web-tools/php-array-to-excel" element={<ExcelConverter />} />
        <Route path="/web-tools/invoice-generator" element={<InvoiceGenerator />} />
        {/* <Route
          path="/web-tools/php-date-format-code-generator"
          element={<DateConverterForm />}
        /> */}
        <Route path="/web-tools/image-to-webp-converter" element={<ImageToWebPConverter />} />

        {/* CSS Generators */}
        <Route path="/css-generators" element={<CssGenerators />} />
        <Route path="/css-generators/css-button-generator" element={<ButtonStyler />} />
        <Route path="/css-generators/css-border-radius-generator" element={<BorderRadiusControl />} />
        <Route path="/css-generators/css-boxshadow-generator" element={<BoxShadowGenerator />} />
        <Route path="/css-generators/css-triangle-generator" element={<TriangleGenerator />} />
        <Route path="/css-generators/css-gradient-generator" element={<GradientGenerator />} />

        {/* SEO Tools */}
        <Route path="/seo-tools" element={<SeoTools />} />
        <Route path="/seo-tools/title-description-tool" element={<TitleDescriptionTool />} />
        <Route path="/seo-tools/text-to-slug" element={<SlugConverter />} />

        {/* Online Calculators */}
        <Route path="/online-calculators" element={<OnlineCalculators />} />
        <Route path="/online-calculators/age-calculator" element={<AgeCalculator />} />
        <Route path="/online-calculators/loan-calculator" element={<LoanCalculator />} />
        <Route path="/online-calculators/paypal-fee-calculator" element={<PayPalCalculator />} />
        <Route path="/online-calculators/percentage-calculator" element={<PercentageCalculator />} />
        <Route path="/online-calculators/mutual-fund-calculator" element={<MutualFundCalculator  />} />
        <Route path="/online-calculators/bmi-calculator" element={<BMICalculator  />} />

        {/* Static Pages */}
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/contact" element={<ContactForm />} />

        {/* Fallback Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
